import { GET_ALL_DISEASE_TREATMENTS, EDIT_DISEASE_TREATMENT, SAVE_DISEASE_TREATMENT, DELETE_DISEASE_TREATMENT } from "../actionType";
import { axiosInstance, getAllDiseaseTreatments, addDiseaseTreatment, editDiseaseTreatment, deleteDiseaseTreatment } from "../../services/Apis";

export const getAllDiseaseTreatmentsAction = (diseaseId) => (dispatch) => {
  axiosInstance
    .get(getAllDiseaseTreatments+"/"+diseaseId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchBodyPart);
      dispatch({
        type: GET_ALL_DISEASE_TREATMENTS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editDiseaseTreatmentAction = (data, id) => (dispatch) => {
  axiosInstance
  .put(editDiseaseTreatment+"/"+id, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_DISEASE_TREATMENT,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveDiseaseTreatmentAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addDiseaseTreatment, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_DISEASE_TREATMENT,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteDiseaseTreatmentAction = (id) => (dispatch) => {
  axiosInstance
  .delete(deleteDiseaseTreatment+"/"+id, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_DISEASE_TREATMENT,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

import { GET_ALL_TECHNIQUES, EDIT_TECHNIQUE, SAVE_TECHNIQUE, DELETE_TECHNIQUE } from "../actionType";

const initialState = {
  techniques: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TECHNIQUES:
      return {
        ...state,
        techniques: action.payload,
      };

    case EDIT_TECHNIQUE:
      return {
        ...state,
      };

    case SAVE_TECHNIQUE:
      return {
        ...state,
      };

    case DELETE_TECHNIQUE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_CITY_TREATMENT_BEFORE_AFTER, EDIT_CITY_TREATMENT_BEFORE_AFTER, SAVE_CITY_TREATMENT_BEFORE_AFTER, DELETE_CITY_TREATMENT_BEFORE_AFTER } from "../actionType";

const initialState = {
  images: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CITY_TREATMENT_BEFORE_AFTER:
      return {
        ...state,
        images: action.payload,
      };

    case EDIT_CITY_TREATMENT_BEFORE_AFTER:
      return {
        ...state,
      };

    case SAVE_CITY_TREATMENT_BEFORE_AFTER:
      return {
        ...state,
      };

    case DELETE_CITY_TREATMENT_BEFORE_AFTER:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

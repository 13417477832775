import React, { useState, useEffect, useRef } from 'react';
import {  useParams } from "react-router-dom";

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup, TextField, Autocomplete, Typography, Breadcrumbs, Link, Select, MenuItem } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import bodyPartSymptomTableData from "layouts/bodyPartSymptom/data/bodyPartSymptomTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getSingleBodyPartAction } from '../../store/BodyPartManagement/action';
import { getAllSymptomAction } from '../../store/SymptomManagement/action';
import { getAllDiseasesAction } from '../../store/DiseaseManagement/action';
import { saveBodyPartSymptomAction, editBodyPartSymptomAction, getAllBodyPartSymptomsAction, saveBodyPartSymptomDiseaseAction } from '../../store/BodyPartSymptomManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let symptoms = [];
let diseases = [];

function Diseases() {
  let { bodyPartId } = useParams();
  const bodyPartState = useSelector(state=> state.bodyPart); 
  const symptomState = useSelector(state=> state.symptom); 
  const diseaseState = useSelector(state=> state.disease);
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  
  const [symptomDiseases, setSymptomDiseases] = React.useState([]);
  const [diseaseValue, setDiseaseValue] = React.useState([]);

  //Form Data
  const [gender, setGender] = useState("");
  const [bodyPartSymptomId, setBodyPartSymptomId] = useState("");
  const [symptomId, setSymptomId] = useState();
  const [bodyPart, setBodyPart] = useState({});
  const [symptom, setSymptom] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setGender("");
    setBodyPartSymptomId("");
    setShowActive(false);
    setOpen(false);
  };

  const [openDisease, setOpenDisease] = useState(false);
  const handleOpenDisease = () => setOpenDisease(true);
  const handleCloseDisease = () => {
    setSymptomDiseases([]);
    setOpenDisease(false);
  };

  const fileInput = useRef();

  const { columns, rows } = bodyPartSymptomTableData({handleOpen, handleOpenDisease, setGender, setShowActive, bodyPartId, setSymptomDiseases, setBodyPartSymptomId, setSymptom, setSymptomId });

  useEffect(() => {
    dispatch(getSingleBodyPartAction(bodyPartId));       
  }, [bodyPartId]);

  useEffect(() => {
    dispatch(getAllSymptomAction());       
  }, []);

  useEffect(() => {
    dispatch(getAllDiseasesAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (bodyPartState.bodyPart !== undefined) {
      setBodyPart(bodyPartState.bodyPart);
    }   
  }, [bodyPartState]);

  useEffect(() => {
    //Lists
    if (symptomState.symptoms !== undefined) {
      //console.log(symptomState.symptoms);
      symptoms = symptomState.symptoms;
    }   
  }, [symptomState]);

  useEffect(() => {
    if (diseaseState.diseases !== undefined) {
      diseases = diseaseState.diseases;
    }
  }, [diseaseState]);

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const uploadBodyPartSymptom = (event) => {
    event.preventDefault();
    if (bodyPartSymptomId)
    {
      const formData = {
        "gender" : gender,
        "body_part_id" : bodyPartId,
        "symptom_id" : symptomId,
        "diseases" : diseaseValue
      }
      dispatch(editBodyPartSymptomAction(formData, bodyPartSymptomId));
      setBodyPartSymptomId("");
      dispatch(getAllBodyPartSymptomsAction(bodyPartId)); 
      handleClose();
    }else{
      const formData = {
        "gender" : gender,
        "body_part_id" : bodyPartId,
        "symptom_id" : symptomId,
        "diseases" : diseaseValue
      }
      dispatch(saveBodyPartSymptomAction(formData));
      dispatch(getAllBodyPartSymptomsAction(bodyPartId)); 
      handleClose();
    }
  }

  return (
    <DashboardLayout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/backend/dashboard">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/body-parts"
        >
          {bodyPart.name}
        </Link>
        {/* <Link
          underline="hover"
          color="inherit"
          to={"/symptoms/"+bodyPartId}
        >
          {symptom.name}
        </Link> */}
        <Typography color="text.primary">Symptoms</Typography>
      </Breadcrumbs>
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Symptom
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadBodyPartSymptom}>
                <FormGroup>
                  <Grid item xs={12}>
                    <Autocomplete
                        id="size-small-standard-multi"
                        size="small"
                        options={symptoms}
                        onChange={(event, value) => setSymptomId(value.symptom_id)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={symptom}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={"symptom"+option.symptom_id}>
                                {option.name}
                              </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Symptom"
                                variant="standard"
                                //placeholder="Keyword"
                            />
                        )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Gender</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Gender"
                        value={gender}
                        onChange={handleGenderChange}
                      >
                        <MenuItem value={1}>Male</MenuItem>
                        <MenuItem value={2}>Female</MenuItem>
                        <MenuItem value={3}>Both</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="size-small-standard-multi"
                      size="small"
                      options={diseases}
                      onChange={(event, value) => setDiseaseValue(value)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={symptomDiseases}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Diseases"
                          variant="standard"
                          //placeholder="Keyword"
                        />
                      )}
                    />
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={() => handleClose()}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Symptoms
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Diseases;

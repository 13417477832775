import {GET_ALL_CITY_CENTERS, EDIT_CITY_CENTER, SAVE_CITY_CENTER, DELETE_CITY_CENTER } from "../actionType";
import { axiosInstance, getAllCityCenters, addCityCenter, editCityCenter, deleteCityCenter } from "../../services/Apis";

export const getAllCityCentersAction = (cityId) => (dispatch) => {
  axiosInstance
    .get(getAllCityCenters+"/"+cityId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchBodyPart);
      dispatch({
        type: GET_ALL_CITY_CENTERS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editCityCenterAction = (data, cityId, centreId) => (dispatch) => {
  axiosInstance
  .put(editCityCenter+"/"+centreId, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_CITY_CENTER,
      payload: response.data,
    });
  })
  .then(dispatch(getAllCityCentersAction(cityId)))
  .catch((error) => console.log(error));
};

export const saveCityCenterAction = (formData, cityId) => (dispatch) => {
  axiosInstance
  .post(addCityCenter, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_CITY_CENTER,
      payload: response.data,
    });
  })
  .then(dispatch(getAllCityCentersAction(cityId)))
  .catch((error) => console.log(error));
};

export const deleteCityCenterAction = (id, cityId) => (dispatch) => {
  axiosInstance
  .delete(deleteCityCenter+"/"+id, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_CITY_CENTER,
      payload: response.data,
    });
  })
  .then(dispatch(getAllCityCentersAction(cityId)))
  .catch((error) => console.log(error));
};

import { GET_ALL_SPECIALIZATIONS, EDIT_SPECIALIZATION, SAVE_SPECIALIZATION, DELETE_SPECIALIZATION } from "../actionType";

const initialState = {
  specializations: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SPECIALIZATIONS:
      return {
        ...state,
        specializations: action.payload,
      };

    case EDIT_SPECIALIZATION:
      return {
        ...state,
      };

    case SAVE_SPECIALIZATION:
      return {
        ...state,
      };

    case DELETE_SPECIALIZATION:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_DOCTOR_MEMBERSHIPS, EDIT_DOCTOR_MEMBERSHIP, SAVE_DOCTOR_MEMBERSHIP, DELETE_DOCTOR_MEMBERSHIP  } from "../actionType";

const initialState = {
  doctorMemberships: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DOCTOR_MEMBERSHIPS:
      return {
        ...state,
        doctorMemberships: action.payload
      };

    case EDIT_DOCTOR_MEMBERSHIP:
      return {
        ...state,
      };

    case SAVE_DOCTOR_MEMBERSHIP:
      return {
        ...state,
      };

    case DELETE_DOCTOR_MEMBERSHIP:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

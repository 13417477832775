import { GET_ALL_VIDEOS, EDIT_VIDEO, SAVE_VIDEO, DELETE_VIDEO } from "../actionType";
import { axiosInstance, getAllVideos, addVideo, editVideo, deleteVideo } from "../../services/Apis";

export const getAllVideosAction = (treatmentId) => (dispatch) => {
  axiosInstance
    .get(getAllVideos+"/"+treatmentId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      dispatch({
        type: GET_ALL_VIDEOS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editVideoAction = (formData, videoId, treatmentId) => (dispatch) => {
  axiosInstance
  .put(editVideo+"/"+videoId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_VIDEO,
      payload: response.data,
    });
    
    if(response.data.status == true)
    {
      console.log(response.data.status);
      getAllVideosAction(treatmentId);
    }
  })
  .catch((error) => console.log(error));
};

export const saveVideoAction = (formData, treatmentId) => (dispatch) => {
  axiosInstance
  .post(addVideo, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_VIDEO,
      payload: response.data,
    });
    if(response.data.status == true)
    {
      getAllVideosAction(treatmentId);
    }
  })
  .catch((error) => console.log(error));
};

export const deleteVideoAction = (videoId, treatmentId) => (dispatch) => {
  axiosInstance
  .delete(deleteVideo+"/"+videoId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_VIDEO,
      payload: response.data,
    });
    if(response.data.status == true)
    {
      getAllVideosAction(treatmentId);
    }
  })
  .catch((error) => console.log(error));
};

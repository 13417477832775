/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

import { region, accessKeyId, secretAccessKey, s3Url, S3, bucketName } from '../../../config';
import { Link } from "react-router-dom";
// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";
import { useParams } from "react-router-dom";

import {useDispatch, useSelector} from 'react-redux';
import { getAllCityCentersAction, deleteCityCenterAction } from '../../../store/CityCenterManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.cityCenter);
  const dispatch = useDispatch();
  let { cityId } = useParams();

  const [cityCentres, setCityCentres] = useState([]);
  const [getAllCentres, setAllCentres] = useState([]);


  useEffect(() => {
    dispatch(getAllCityCentersAction(cityId));       
  }, []);

  useEffect(() => {
    //Lists
    if (state.cityCenters !== undefined) {
      setCityCentres(state.cityCenters);
      // console.log(state.cityCenters);
    }    
    // axios.get("http://api.tamiraplatform.metricserp.net/api/v1/doctor/fetch-all-banner",{
    //   headers: {
    //     "x-auth-token": sessionStorage.getItem('userToken') //the token is a variable which holds the token
    //   }
    // })
    // .then(result => {
    //   console.log(result.data.fetchBanner);
    //   setDoctors(result.data.fetchBanner);
    // })
  }, [state]);

  const handleDeleteDoctor = (index) => {
    // const config = {
    //   bucketName : bucketName,
    //   dirName : "doctor",
    //   region : region,
    //   accessKeyId : accessKeyId,
    //   secretAccessKey : secretAccessKey
    // };
    // const ReactS3Client = new S3(config);
    // ReactS3Client
    // .deleteFile(s3Url+"/doctor/"+doctors[index].upload_image)
    // .then((response) => {
    //   dispatch(deleteDoctorAction(doctors[index].user_id));   
    //   dispatch(getAllDoctorsAction());
    // })
    // .catch(err => console.error(err))
    dispatch(deleteCityCenterAction(cityCentres[index].id, cityId));   
    dispatch(getAllCityCentersAction(cityId));
  }

  const handleEditDoctor = (cityCentres) => {
    // console.log(doctor);
    props.setName(cityCentres.centre_name);
    props.setShowName(cityCentres.centre_image);
    props.setAlt(cityCentres.alt_text);
    props.setLocation(cityCentres.centre_location);
    props.setLink(cityCentres.centre_link);
    props.setCentreId(cityCentres.id);
    props.setShowActive(true);
    props.handleOpen();
  };

  

  useEffect(() => {
    
    const allCentres = cityCentres.map((centre, index)=> (
      {
        centreImage: <Author image={s3Url+"/cityCentre/"+centre.centre_image} name={centre.centre_name} />,
        name: centre.centre_name,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={()=>handleEditDoctor(centre)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteDoctor(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));

    setAllCentres(allCentres);
    //console.log('ddd',allDoctors1);
  }, [cityCentres]);

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Centre Image", accessor: "centreImage", width: "25%", align: "left" },
      { Header: "Name", accessor: "name", width: "15%", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: getAllCentres
  };
}

import { GET_ALL_DOCTORS, GET_SINGLE_DOCTOR, EDIT_DOCTOR, SAVE_DOCTOR, DELETE_DOCTOR } from "../actionType";
import { axiosInstance, getAllDoctors, addDoctor, deleteDoctor, editDoctor } from "../../services/Apis";

export const getAllDoctorsAction = () => (dispatch) => {
  axiosInstance
    .get(getAllDoctors, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchDoctor[0]);
      dispatch({
        type: GET_ALL_DOCTORS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const getSingleDoctorAction = (doctorId) => (dispatch) => {
  axiosInstance
    .get(getAllDoctors+"/"+doctorId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchDoctor[0]);
      dispatch({
        type: GET_SINGLE_DOCTOR,
        payload: response.data.data[0],
      });
    })
    .catch((error) => console.log(error));
};

export const editDoctorAction = (doctorId, data) => (dispatch) => {
  axiosInstance
  .put(editDoctor+"/"+doctorId, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_DOCTOR,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveDoctorAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addDoctor, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_DOCTOR,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteDoctorAction = (doctorId) => (dispatch) => {
  axiosInstance
  .delete(deleteDoctor+"/"+doctorId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_DOCTOR,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

import { GET_ALL_DOCTOR_MEMBERSHIPS, EDIT_DOCTOR_MEMBERSHIP, SAVE_DOCTOR_MEMBERSHIP, DELETE_DOCTOR_MEMBERSHIP  } from "../actionType";
import { axiosInstance, getAllDoctorMemberships, addDoctorMembership, editDoctorMembership, deleteDoctorMembership } from "../../services/Apis";

export const getAllDoctorMembershipActions = (doctorId) => (dispatch) => {
  axiosInstance
    .get(getAllDoctorMemberships+"/"+doctorId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchCause);
      dispatch({
        type: GET_ALL_DOCTOR_MEMBERSHIPS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editDoctorMembershipAction = (formData, membershipId) => (dispatch) => {
  axiosInstance
  .put(editDoctorMembership+"/"+membershipId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_DOCTOR_MEMBERSHIP,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveDoctorMembershipAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addDoctorMembership, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_DOCTOR_MEMBERSHIP,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteDoctorMembershipAction = (memberShipId) => (dispatch) => {
  axiosInstance
  .delete(deleteDoctorMembership+"/"+memberShipId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_DOCTOR_MEMBERSHIP,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

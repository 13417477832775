import React, { useState, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import S3 from "react-aws-s3";
import {  useParams } from "react-router-dom";
import { accessKeyId, secretAccessKey, region, s3Url, bucketName, editorKey } from '../../config';

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup, TextField, Autocomplete, Typography, Breadcrumbs, Link } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import diseaseTableData from "layouts/disease/data/diseaseTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getSingleBodyPartAction } from '../../store/BodyPartManagement/action';
import { getSingleSymptomAction } from '../../store/SymptomManagement/action';
import { getAllTreatmentsAction } from '../../store/TreatmentManagement/action';
import { getAllMedicinesAction } from '../../store/MedicineManagement/action';
import { saveDiseaseAction, editDiseaseAction, getAllDiseasesAction } from '../../store/DiseaseManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let treatments = [];
let medicines = [];

function Diseases() {
  let { bodyPartId } = useParams();
  let { symptomId } = useParams();
  const bodyPartState = useSelector(state=> state.bodyPart); 
  const symptomState = useSelector(state=> state.symptom); 
  const treatmentState = useSelector(state=> state.treatment); 
  const medicineState = useSelector(state=> state.medicine); 
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  const [file, setFile] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [showName, setShowName] = React.useState("");
  const [s3fileUrl, setS3FileUrl] = React.useState("");

  const [treatmentValue, setTreatmentValue] = React.useState([]);
  const [medicineValue, setMedicineValue] = React.useState([]);

  //Form Data
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [description1, setDescription1] = useState("");
  const [diseaseTreatments, setDiseaseTreatments] = React.useState([]);
  const [diseaseMedicines, setDiseaseMedicines] = React.useState([]);
  const [diseaseId, setDiseaseId] = useState("");
  const [bodyPart, setBodyPart] = useState({});
  const [symptom, setSymptom] = useState({});
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [altText, setAltText] = useState('');

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    setDescription("");
    setDiseaseTreatments([]);
    setDiseaseMedicines([]);
    setDiseaseId("");
    setShowName("");
    setShowActive(false);
    setOpen(false);
    setMetaTitle("");
    setMetaDescription("");
    setAltText("");
  };

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g,"");  
    var random = ("" + Math.random()).substring(2, 8); 
    var random_number = timestamp+random;  
    return random_number;
  }

  const fileInput = useRef();

  const { columns, rows } = diseaseTableData({handleOpen, setName, setMetaTitle, setMetaDescription, setDescription, setDescription1, setShowName, setShowActive, setDiseaseId, setDiseaseTreatments, setDiseaseMedicines, bodyPartId, symptomId, setAltText });
  const handleFileInput = (e) => {
    // handle validations
    //alert(1);
    setFile(e.target.files[0]);
    setFileName(getRandomFileName()+e.target.files[0].name);
  }

  useEffect(() => {
    dispatch(getAllTreatmentsAction());       
  }, []);

  useEffect(() => {
    dispatch(getAllMedicinesAction());       
  }, []);

  useEffect(() => {
    dispatch(getSingleBodyPartAction(bodyPartId));       
  }, [bodyPartId]);

  useEffect(() => {
    dispatch(getSingleSymptomAction(symptomId));       
  }, [symptomId]);

  useEffect(() => {
    if (bodyPartState.bodyPart !== undefined) {
      setBodyPart(bodyPartState.bodyPart);
    }   
  }, [bodyPartState]);

  useEffect(() => {
    if (symptomState.symptom !== undefined) {
      setSymptom(symptomState.symptom);
    }   
  }, [symptomState]);

  useEffect(() => {
    //Lists
    if (treatmentState.treatments !== undefined) {
      treatments = treatmentState.treatments;
    }
  }, [treatmentState]);

  useEffect(() => {
    //Lists
    if (medicineState.medicines !== undefined) {
      medicines = medicineState.medicines;
    }
  }, [medicineState]);

  const uploadDisease = (event) => {
    event.preventDefault();
    const config = {
      bucketName : bucketName,
      dirName : "disease",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (diseaseId)
    {
      if(fileName != "")
      {
        ReactS3Client
        .deleteFile(s3Url+"/disease/"+showName)
        .then((response) => {
          ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            setS3FileUrl(data.location);
            const formData = {
              "name" : name,
              "description" : description,
              "upload_image" : fileName,
              "body_part_id" : bodyPartId,
              "symptom_id" : symptomId,
              "treatmentValue" : treatmentValue,
              "medicineValue" : medicineValue,
              "meta_title" : metaTitle,
              "meta_description" : metaDescription,
              "disease_alt_text":altText
            }
            dispatch(editDiseaseAction(formData, diseaseId));
            setDiseaseId("");
            dispatch(getAllDiseasesAction(bodyPartId, symptomId)); 
            handleClose();
          })
        })
        .catch(err => console.error(err))
      }else{
        const formData = {
          "name" : name,
          "description" : description,
          "body_part_id" : bodyPartId,
          "symptom_id" : symptomId,
          "treatmentValue" : treatmentValue,
          "medicineValue" : medicineValue,
          "meta_title" : metaTitle,
          "meta_description" : metaDescription,
          "disease_alt_text":altText
        }
        dispatch(editDiseaseAction(formData, diseaseId));
        setDiseaseId("");
        dispatch(getAllDiseasesAction(bodyPartId, symptomId)); 
        handleClose();
      }
    }else{
      ReactS3Client.uploadFile(file, fileName)
      .then(data => {
        //console.log(data.location);
        setS3FileUrl(data.location);
        const formData = {
          "name" : name,
          "description" : description,
          "upload_image" : fileName,
          "body_part_id" : bodyPartId,
          "symptom_id" : symptomId,
          "treatmentValue" : treatmentValue,
          "medicineValue" : medicineValue,
          "meta_title" : metaTitle,
          "meta_description" : metaDescription,
          "disease_alt_text":altText
        }
        dispatch(saveDiseaseAction(formData));
        dispatch(getAllDiseasesAction(bodyPartId, symptomId)); 
        handleClose();
      })
    }
  }

  return (
    <DashboardLayout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/backend/dashboard">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          to="/body-parts"
        >
          {bodyPart.name}
        </Link>
        <Link
          underline="hover"
          color="inherit"
          to={"/symptoms/"+bodyPartId}
        >
          {symptom.name}
        </Link>
        <Typography color="text.primary">Diseases</Typography>
      </Breadcrumbs>
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Disease
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
            disableAutoFocus={true} //for disable modal
            disableEnforceFocus={true} //for disable modal
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadDisease}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Title</InputLabel>
                      <Input id="title" value={name} aria-describedby="my-helper-text" onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Meta Title</InputLabel>
                      <Input id="metatitle" value={metaTitle} aria-describedby="my-helper-text" onChange={(e) => setMetaTitle(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Meta Description</InputLabel>
                      <Input id="metadescription" value={metaDescription} aria-describedby="my-helper-text" onChange={(e) => setMetaDescription(e.target.value)} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Description</InputLabel>
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setDescription(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={description1}
                        // init={{
                        //   height: 500,
                        //   menubar: true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="size-small-standard-multi"
                        size="small"
                        options={treatments}
                        onChange={(event, value) => setTreatmentValue(value)}
                        getOptionLabel={(option) => option.title}
                        defaultValue={diseaseTreatments}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={"treatment"+option.treatment_id}>
                                {option.title}
                              </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Treatment"
                                variant="standard"
                                //placeholder="Keyword"
                            />
                        )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="size-small-standard-multi"
                        size="small"
                        options={medicines}
                        onChange={(event, value) => setMedicineValue(value)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={diseaseMedicines}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={"medicine"+option.medicine_id}>
                                {option.name}
                              </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Medicine"
                                variant="standard"
                                //placeholder="Keyword"
                            />
                        )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Alt Text</InputLabel>
                      <Input id="alttext" value={altText} aria-describedby="my-helper-text" onChange={(e) => setAltText(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Image</InputLabel>
                      <Box component="img" src={s3Url+"/disease/"+showName} width={100}></Box>
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Diseases
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Diseases;

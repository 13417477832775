import { GET_SINGLE_DISEASE, GET_ALL_DISEASES, EDIT_DISEASE, SAVE_DISEASE, DELETE_DISEASE } from "../actionType";

const initialState = {
  disease: {},
  diseases: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_DISEASE:
      return {
        ...state,
        disease: action.payload,
      };

    case GET_ALL_DISEASES:
      return {
        ...state,
        diseases: action.payload,
      };

    case EDIT_DISEASE:
      return {
        ...state,
      };

    case SAVE_DISEASE:
      return {
        ...state,
      };

    case DELETE_DISEASE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

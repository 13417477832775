import React, { useState, useEffect, useRef } from 'react';

import S3 from "react-aws-s3";
import { Editor } from '@tinymce/tinymce-react';

import { accessKeyId, secretAccessKey, region, s3Url, bucketName, editorKey } from '../../config';
// @mui material components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup, Select, MenuItem } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import ivTherapyTableData from "layouts/ivTherapy/data/ivTherapyTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getAllTherapyAction, editTherapyAction, saveTherapyAction } from '../../store/IvTherapyManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function IvTherapy() {
  const state = useSelector(state => state.ivTherapy);
  const dispatch = useDispatch();

  const [showActive, setShowActive] = React.useState(false);
  const [file, setFile] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [showName, setShowName] = React.useState("");

  const [showActive1, setShowActive1] = React.useState(false);
  const [therapyImage, setTherapyImage] = React.useState("");
  const [therapyImageName, setTherapyImageName] = React.useState("");
  const [showTherapyImageName, setShowTherapyImageName] = React.useState("");

  const [showActive2, setShowActive2] = React.useState(false);
  const [logoImage, setLogoImage] = React.useState("");
  const [logoImageName, setLogoImageName] = React.useState("");
  const [showLogoImageName, setShowLogoImageName] = React.useState("");

  const [s3fileUrl, setS3FileUrl] = React.useState("");

  //Form Data
  const [title, setTitle] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [alt, setAlt] = useState('');
  const [introduction, setIntroduction] = useState("");
  const [benefits, setBenefits] = useState("");
  const [whocantherapy, setWhocantherapy] = useState("");
  const [doctorId, setDoctorId] = useState("");


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setTitle("");
    setShortDesc("");
    setIntroduction("");
    setBenefits("");
    setWhocantherapy("");
    setDoctorId("");
    setShowName("");
    setShowTherapyImageName("");
    setShowActive(false);
    setShowActive1(false);
    setShowActive2(false);
    setAlt("");
    setOpen(false);
  };

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    var random = ("" + Math.random()).substring(2, 8);
    var random_number = timestamp + random;
    return random_number;
  }

  const fileInput = useRef();
  const fileInput2 = useRef();
  const fileInput3 = useRef();

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    setFileName(getRandomFileName() + e.target.files[0].name);
  }
  const handleFileInput2 = (e) => {
    setTherapyImage(e.target.files[0]);
    setTherapyImageName(getRandomFileName() + e.target.files[0].name);
  }

  const handleFileInput3 = (e) => {
    setLogoImage(e.target.files[0]);
    setLogoImageName(getRandomFileName() + e.target.files[0].name);
  }

  const { columns, rows } = ivTherapyTableData({ handleOpen, setTitle, setShortDesc, setIntroduction, setBenefits, setWhocantherapy, setShowName, setShowTherapyImageName, setShowLogoImageName, setShowActive, setShowActive1, setShowActive2, setDoctorId, setAlt });


  const uploadDoctor = (event) => {
    event.preventDefault();
    const config = {
      bucketName: bucketName,
      dirName: "ivtherapy-who-can-therapy",
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey
    };
    const ReactS3Client = new S3(config);

    let therapyImageName1 = "";
    let ivtherapyLogo1 = "";

    if (therapyImageName != "") {
      if (showTherapyImageName == "") {
        ReactS3Client.uploadFile(therapyImage, therapyImageName)
          .then(data1 => {
            therapyImageName1 = therapyImageName;
            //setMainImageName(imageName);
          })
        therapyImageName1 = therapyImageName;
      } else {
        ReactS3Client.uploadFile(therapyImage, therapyImageName)
          .then(data1 => {
            //setMainImageName(imageName);
          })
          .catch(err => console.error(err));
        therapyImageName1 = therapyImageName;
      }
    } else {
      therapyImageName1 = showTherapyImageName;
    }


    if (logoImageName != "") {
      if (showLogoImageName == "") {
        ReactS3Client.uploadFile(logoImage, logoImageName)
          .then(data1 => {
            ivtherapyLogo1 = logoImageName;
            //setMainImageName(imageName);
          })
          ivtherapyLogo1 = logoImageName;
      } else {
        ReactS3Client.uploadFile(logoImage, logoImageName)
          .then(data1 => {
            //setMainImageName(imageName);
          })
          .catch(err => console.error(err));
          ivtherapyLogo1 = logoImageName;
      }
    } else {
      ivtherapyLogo1 = showLogoImageName;
    }


    //console.log("mobile image" , mobilebannerName1);
    if (doctorId) {
      if (fileName != "") {
        ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            //setS3FileUrl(data.location);
            const formData = {
              "title": title,
              "short_description":shortDesc,
              "banner_image": fileName,
              "banner_alt": alt,
              "introduction": introduction,
              "benefits": benefits,
              "who_can_therapy": whocantherapy,
              "who_can_therapy_image": therapyImageName1,
              "ivtherapy_logo":ivtherapyLogo1,
              "add_id": "1",
              "edit_id": "1"
            }
            setFileName("");
            setTherapyImageName("");
            setLogoImageName("");
            dispatch(editTherapyAction(doctorId, formData));
            setDoctorId("");
            dispatch(getAllTherapyAction());
            handleClose();
          })
          .catch(err => console.error(err))
      } else {
        const formData = {
          "title": title,
          "short_description":shortDesc,
          "banner_image": showName,
          "banner_alt": alt,
          "introduction": introduction,
          "benefits": benefits,
          "who_can_therapy": whocantherapy,
          "who_can_therapy_image": therapyImageName1,
          "ivtherapy_logo":ivtherapyLogo1,
          "add_id": "1",
          "edit_id": "1"
        }
        setFileName("");
        setTherapyImageName("");
        setLogoImageName("");
        dispatch(editTherapyAction(doctorId, formData));
        setDoctorId("");
        dispatch(getAllTherapyAction());
        handleClose();
      }
    } else {
      if (fileName != "") {
        ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            setS3FileUrl(data.location);
            const formData = {
              "title": title,
              "short_description":shortDesc,
              "banner_image": fileName,
              "banner_alt": alt,
              "introduction": introduction,
              "benefits": benefits,
              "who_can_therapy": whocantherapy,
              "who_can_therapy_image": therapyImageName1,
              "ivtherapy_logo":ivtherapyLogo1,
              "add_id": "1",
              "edit_id": "1"
            }
            setFileName("");
            setTherapyImageName("");
            setLogoImageName("");
            dispatch(saveTherapyAction(formData, doctorId));
            dispatch(getAllTherapyAction());
            handleClose();
          })
      } else {
        let therapyImageName1 = "";

        if (therapyImage == "") {
          ReactS3Client.uploadFile(therapyImage, therapyImageName)
            .then(data1 => {
              therapyImageName1 = therapyImageName;
              //setMainImageName(imageName);
            })
          therapyImageName1 = therapyImage;
        } else {
          therapyImageName1 = therapyImage;
        }

        let ivtherapyLogo1 = "";

        if (therapyImage == "") {
          ReactS3Client.uploadFile(logoImage, logoImageName)
            .then(data1 => {
              ivtherapyLogo1 = logoImageName;
              //setMainImageName(imageName);
            })
            ivtherapyLogo1 = logoImage;
        } else {
          ivtherapyLogo1 = logoImage;
        }

        const formData = {
          "title": title,
          "banner_image": "",
          "short_description":shortDesc,
          "banner_alt": alt,
          "introduction": introduction,
          "benefits": benefits,
          "who_can_therapy": whocantherapy,
          "who_can_therapy_image": therapyImageName1,
          "ivtherapy_logo":ivtherapyLogo1,
          "add_id": "1",
          "edit_id": "1"
        }
        setFileName("");
        setTherapyImageName("");
        setLogoImageName("");
        dispatch(saveTherapyAction(formData, doctorId));
        dispatch(getAllTherapyAction());
        handleClose();

      }
    }
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Ivtherapy
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
            disableAutoFocus={true} //for disable modal
            disableEnforceFocus={true} //for disable modal
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadDoctor}>
                <FormGroup>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Title</InputLabel>
                      <Input id="title" value={title} aria-describedby="my-helper-text" onChange={(e) => setTitle(e.target.value)} />
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Short Description</InputLabel>
                      <Input id="title" value={shortDesc} aria-describedby="my-helper-text" onChange={(e) => setShortDesc(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Introduction</InputLabel>
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setIntroduction(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        value={introduction}
                        // init={{
                        //   height: 500,
                        //   menubar: true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Benefits</InputLabel>
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setBenefits(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        value={benefits}
                        // init={{
                        //   height: 500,
                        //   menubar: true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Who Can Therapy</InputLabel>
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setWhocantherapy(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        value={whocantherapy}
                        // init={{
                        //   height: 500,
                        //   menubar: true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Alt Text</InputLabel>
                      <Input id="alt tag" aria-describedby="my-helper-text" value={alt} onChange={(e) => setAlt(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Logo</InputLabel>
                      <Input type="file" ref={fileInput3} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput3} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} className={showActive2 ? "show" : "hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Logo Image</InputLabel>
                      <Box component="img" src={s3Url + "/ivtherapy-who-can-therapy/" + showLogoImageName} style={{ "width": 100 }}></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Banner Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={showActive ? "show" : "hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Banner Image</InputLabel>
                      <Box component="img" src={s3Url + "/ivtherapy-who-can-therapy/" + showName} style={{ "width": 100 }}></Box>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Who Can Therapy Image</InputLabel>
                      <Input type="file" ref={fileInput2} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput2} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} className={showActive1 ? "show" : "hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Who Can Therapy Image</InputLabel>
                      <Box component="img" src={s3Url + "/ivtherapy-who-can-therapy/" + showTherapyImageName} style={{ "width": 100 }}></Box>
                    </FormControl>
                  </Grid>



                </FormGroup>
                <MDButton type="submit" disabled={!title || !shortDesc ? true : false }>
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Ivtherapies
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default IvTherapy;

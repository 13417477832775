import { GET_ALL_TESTIMONIALS, EDIT_TESTIMONIAL, SAVE_TESTIMONIAL, DELETE_TESTIMONIAL } from "../actionType";

const initialState = {
  testimonials: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TESTIMONIALS:
      return {
        ...state,
        testimonials: action.payload,
      };

    case EDIT_TESTIMONIAL:
      return {
        ...state,
      };

    case SAVE_TESTIMONIAL:
      return {
        ...state,
      };

    case DELETE_TESTIMONIAL:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_DOCTOR_CLINICS, EDIT_DOCTOR_CLINIC, SAVE_DOCTOR_CLINIC, DELETE_DOCTOR_CLINIC  } from "../actionType";

const initialState = {
  doctorClinics: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DOCTOR_CLINICS:
      return {
        ...state,
        doctorClinics: action.payload
      };

    case EDIT_DOCTOR_CLINIC:
      return {
        ...state,
      };

    case SAVE_DOCTOR_CLINIC:
      return {
        ...state,
      };

    case DELETE_DOCTOR_CLINIC:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import S3 from "react-aws-s3";

import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllMedicinesAction, deleteMedicineAction } from '../../../store/MedicineManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.medicine);
  const dispatch = useDispatch();

  const [medicines, setMedicines] = useState([]);
  const [allMedicines, setAllMedicines] = useState([]);


  useEffect(() => {
    dispatch(getAllMedicinesAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (state.medicines !== undefined) {
      setMedicines(state.medicines);
    }
  }, [state]);

  const handleDeleteMedicine = (index) => {
    const config = {
      bucketName : bucketName,
      dirName : "medicine",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    ReactS3Client
    .deleteFile(s3Url+"/medicine/"+medicines[index].upload_image)
    .then((response) => {})
    .catch(err => console.error(err))
    dispatch(deleteMedicineAction(medicines[index].medicine_id));   
    dispatch(getAllMedicinesAction());
  }

  const handleEditMedicine = (medicine) => {
    props.setName(medicine.name);
    props.setPrice(medicine.price);
    props.setDescription(medicine.description);
    props.setShowName(medicine.upload_image);
    props.setMedicineId(medicine.medicine_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    
    const allMedicines1 = medicines.map((medicine, index) => (
      {
        medicineImage: <Author image={s3Url+"/medicine/"+medicine.upload_image} name={medicine.name} />,
        medicineName: medicine.name,
        medicinePrice: medicine.price,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={()=>handleEditMedicine(medicine)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteMedicine(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllMedicines(allMedicines1);
  }, [medicines]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Medicine Image", accessor: "medicineImage", width: "30%", align: "left" },
      { Header: "Medicine Name", accessor: "medicineName", width: "30%", align: "left" },
      { Header: "Medicine Price", accessor: "medicinePrice", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Medicine", accessor: "action", align: "center" },
    ],
    rows: allMedicines
  };
}

import { GET_ALL_CITY_TREATMENTS, EDIT_CITY_TREATMENT, SAVE_CITY_TREATMENT, DELETE_CITY_TREATMENT } from "../actionType";
import { axiosInstance, getAllCityTreatments, addCityTreatment, editCityTreatment, deleteCityTreatment } from "../../services/Apis";

export const getAllCityTreatmentsAction = (cityId) => (dispatch) => {
  axiosInstance
    .get(getAllCityTreatments+"/"+cityId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchBodyPart);
      dispatch({
        type: GET_ALL_CITY_TREATMENTS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editCityTreatmentAction = (cityId, data, id) => (dispatch) => {
  axiosInstance
  .put(editCityTreatment+"/"+id, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_CITY_TREATMENT,
      payload: response.data,
    });
  })
  .then(dispatch(getAllCityTreatmentsAction(cityId)))
  .catch((error) => console.log(error));
};

export const saveCityTreatmentAction = (cityId, formData) => (dispatch) => {
  axiosInstance
  .post(addCityTreatment, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_CITY_TREATMENT,
      payload: response.data,
    });
  })
  .then(dispatch(getAllCityTreatmentsAction(cityId)))
  .catch((error) => console.log(error));
};

export const deleteCityTreatmentAction = (cityId, id) => (dispatch) => {
  axiosInstance
  .delete(deleteCityTreatment+"/"+id, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_CITY_TREATMENT,
      payload: response.data,
    });
  })
  .then(dispatch(getAllCityTreatmentsAction(cityId)))
  .catch((error) => console.log(error));
};

import React, { useState, useEffect, useRef } from 'react';

import axios from "axios";

import S3 from "react-aws-s3";

import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../config';

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, CircularProgress } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import beforeafterTableData from "layouts/beforeAfter/data/beforeafterTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getAllTreatmentBodypartsAction } from '../../store/TreatmentManagement/action';
import { getAllDoctorsAction } from 'store/DoctorManagement/action';
import { getAllBeforeAftersAction, editBeforeAfterAction, saveBeforeAfterAction, deleteBeforeAftersAction } from '../../store/BeforeAfterManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let mainimageName1 = "";
let mainafterImageName1 = "";

function Banners() {
  const state = useSelector(state => state.treatment);
  const state1 = useSelector(state => state.doctor);
  const dispatch = useDispatch();

  const [image, setImage] = React.useState([]);
  const [imageName, setImageName] = React.useState("");
  const [mainimageName, setMainImageName] = React.useState("");

  const [hoverImage, setAfterImage] = React.useState([]);
  const [afterImageName, setAfterImageName] = React.useState("");
  const [mainafterImageName, setMainafterImageName] = React.useState("");

  const [showBeforeImageName, setshowBeforeImageName] = React.useState("");
  const [showAfterImageName, setshowAfterImageName] = React.useState("");

  const [s3fileUrl, setS3FileUrl] = React.useState("");

  //Form Data
  const [treatments, setTreatments] = useState([]);
  const [allTreatments, setAlltreatments] = useState([]);

  const [doctors, setDoctors] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);

  const [treatmentId, setTreatmentId] = useState("");
  const [doctorId, setDoctorId] = useState(0);
  const [altText, setAltText] = useState("");
  const [beforeAfterId, setBeforeAfterId] = useState("");
  const [showActive, setShowActive] = React.useState(false);

  const [selectedIds, setSelectedIds] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  //const handleClose = () => setOpen(false);

  const handleClose = () => {
    setshowBeforeImageName("");
    setshowAfterImageName("");
    setTreatmentId("");
    setBeforeAfterId("");
    setShowActive(false);
    setOpen(false);
  };

  const { columns, rows } = beforeafterTableData({handleOpen, setshowBeforeImageName, setshowAfterImageName, setTreatmentId, setDoctorId, setAltText, setBeforeAfterId, setShowActive, setSelectedIds });

  const fileInput = useRef();

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    var random = ("" + Math.random()).substring(2, 8);
    var random_number = timestamp + random;
    return random_number;
  }

  const deletedSeletedItems = () => {    
    var del=confirm("Are you sure you want to delete this record?");
    if (del==true){
      //console.log(selectedIds);
      dispatch(deleteBeforeAftersAction(selectedIds));   
      //alert ("Record deleted")
    }     
  }

  const handleBeforeImageInput = (e) => {
    // handle validations
    //alert(1);
    setImage(e.target.files[0]);
    setImageName(getRandomFileName() + e.target.files[0].name);
  }

  const handleAfterImageInput = (e) => {
    // handle validations
    //alert(1);
    setAfterImage(e.target.files[0]);
    setAfterImageName(getRandomFileName() + e.target.files[0].name);
  }

  const allImagesReinit = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      dispatch(getAllBeforeAftersAction());
    }, 5000);
  }

  useEffect(() => {
    dispatch(getAllTreatmentBodypartsAction());
  }, []);

  useEffect(() => {
    dispatch(getAllDoctorsAction());
  }, []);

  useEffect(() => {
    //Lists
    if (state.treatmentBodyparts !== undefined) {
      setTreatments(state.treatmentBodyparts);
    }
  }, [state]);

  useEffect(() => {
    //Lists
    if (state1.doctors !== undefined) {
      setDoctors(state1.doctors);
    }
  }, [state1]);

  useEffect(() => {
    const treatments1 = treatments.map((treatment, index) => (
      <MenuItem key={index} value={treatment.treatment_id}>{treatment.title + " (" + treatment.name + ")"}</MenuItem>
    ));
    setAlltreatments(treatments1);
  }, [treatments]);

  useEffect(() => {
    const doctors1 = doctors.map((doctor, index) => (
      <MenuItem key={index} value={doctor.user_id}>{doctor.first_name + " " + doctor.last_name}</MenuItem>
    ));
    setAllDoctors(doctors1);
  }, [doctors]);

  const uploadBeforeAfter = (event) => {
    event.preventDefault();
    allImagesReinit();
    const config = {
      bucketName: bucketName,
      dirName: "beforeAfter",
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (beforeAfterId) {
      if (imageName != "") {
        if (showBeforeImageName == "") {
          ReactS3Client.uploadFile(image, imageName)
            .then(data1 => {
              mainimageName1 = imageName;
              //setMainImageName(imageName);
            })
          mainimageName1 = imageName;
        } else {
          ReactS3Client.uploadFile(image, imageName)
            .then(data1 => {
              //setMainImageName(imageName);
            })
            .catch(err => console.error(err));
          mainimageName1 = imageName;
        }
      } else {
        //console.log(showBeforeImageName);
        mainimageName1 = showBeforeImageName;
        //setMainImageName(showBeforeImageName);
      }

      const formData = {
        "before_image": mainimageName1,
        //"after_image" : mainafterImageName1,
        "treatment_id": treatmentId,
        "doctor_id": doctorId,
        "alt_tag": altText
      };
      //console.log(formData);
      dispatch(editBeforeAfterAction(formData, beforeAfterId));
      setBeforeAfterId("");
      dispatch(getAllBeforeAftersAction());
      handleClose();
    } else {
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(image, imageName)
        .then(data => {
          const formData = {
            "before_image": imageName,
            //"after_image" : afterImageName,
            "treatment_id": treatmentId,
            "doctor_id": doctorId,
            "alt_tag": altText
          }
          dispatch(saveBeforeAfterAction(formData));
          dispatch(getAllBeforeAftersAction());
          handleClose();
        })
    }
  }

  return (
    <DashboardLayout>
      {
        isLoading && <div className='c-loader'><CircularProgress /></div>
      }
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Before After
          </MDButton>
          <MDButton onClick={deletedSeletedItems}>
            Delete Selected
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadBeforeAfter}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Treatment</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Treatment"
                        value={treatmentId}
                        onChange={(e) => setTreatmentId(e.target.value)}
                      >
                        {allTreatments}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Doctor</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Doctor"
                        value={doctorId}
                        onChange={(e) => setDoctorId(e.target.value)}
                      >
                        {allDoctors}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Alt Text</InputLabel>
                      <Input id="alt tag" aria-describedby="my-helper-text" value={altText} onChange={(e) => setAltText(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Before After Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleBeforeImageInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={showActive ? "show" : "hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Before After Image</InputLabel>
                      <Box component="img" src={s3Url + "/beforeAfter/" + showBeforeImageName}></Box>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload After Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleAfterImageInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing After Image</InputLabel>
                      <Box component="img" src={s3Url+"/beforeAfter/"+showAfterImageName}></Box>
                    </FormControl>
                  </Grid> */}
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Body Parts
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Banners;
import { GET_ALL_DOCTOR_EDUCATIONS, EDIT_DOCTOR_EDUCATION, SAVE_DOCTOR_EDUCATION, DELETE_DOCTOR_EDUCATION  } from "../actionType";

const initialState = {
  doctorEducations: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DOCTOR_EDUCATIONS:
      return {
        ...state,
        doctorEducations: action.payload
      };

    case EDIT_DOCTOR_EDUCATION:
      return {
        ...state,
      };

    case SAVE_DOCTOR_EDUCATION:
      return {
        ...state,
      };

    case DELETE_DOCTOR_EDUCATION:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_BANNERS, EDIT_BANNER, SAVE_BANNER, DELETE_BANNER } from "../actionType";

const initialState = {
  banners: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BANNERS:
      return {
        ...state,
        banners: action.payload,
      };

    case EDIT_BANNER:
      return {
        ...state,
      };

    case SAVE_BANNER:
      return {
        ...state,
      };

    case DELETE_BANNER:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_SERVICES, EDIT_SERVICE, SAVE_SERVICE, DELETE_SERVICE } from "../actionType";

const initialState = {
  services: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SERVICES:
      return {
        ...state,
        services: action.payload,
      };

    case EDIT_SERVICE:
      return {
        ...state,
      };

    case SAVE_SERVICE:
      return {
        ...state,
      };

    case DELETE_SERVICE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

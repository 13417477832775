import React, { useState, useEffect, useRef } from 'react';
import S3 from "react-aws-s3";
import { accessKeyId, secretAccessKey, region, s3Url } from '../../config';
import { useParams } from "react-router-dom";
// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, Typography, Breadcrumbs, Link } from '@mui/material';
//import Breadcrumbs from "examples/Breadcrumbs";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import faqTableData from "layouts/ivTherapyFaq/data/faqTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getSingleTreatmentAction } from '../../store/TreatmentManagement/action';
import { getAllFaqsAction, saveFaqAction, editFaqAction } from '../../store/IvTherapyFaqManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Options() {
  let { ivTherapyId } = useParams();
  const state = useSelector(state => state.treatment);
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  //Form Data
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [treatment, setTreatment] = useState({});
  const [faqId, setFaqId] = useState("");
  const [isShow, setIsShow] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setQuestion("");
    setAnswer("");
    setFaqId("");
    setShowActive(false);
    setIsShow(false);
    setOpen(false);
  };

  const { columns, rows } = faqTableData({ ivTherapyId, handleOpen, setQuestion, setAnswer, setFaqId, setShowActive, setIsShow });

  // useEffect(() => {
  //   dispatch(getSingleTreatmentAction(ivTherapyId));       
  // }, [ivTherapyId]);

  useEffect(() => {
    //Lists
    if (state.treatment !== undefined) {
      setTreatment(state.treatment);
      //console.log(state.treatment);
    }
  }, [state]);

  const handleChange = (event) => {
    setIsShow(event.target.value);
  };

  const uploadCause = (event) => {
    event.preventDefault();
    if (faqId) {
      const formData = {
        "question": question,
        "answer": answer,
        "is_ivtherapy_page":isShow,
        "ivtherapy_id": ivTherapyId
      }
      dispatch(editFaqAction(formData, faqId, ivTherapyId));
      setFaqId("");
      dispatch(getAllFaqsAction(ivTherapyId));
      handleClose();
    } else {
      const formData = {
        "question": question,
        "answer": answer,
        "is_ivtherapy_page":isShow,
        "ivtherapy_id": ivTherapyId
      }
      dispatch(saveFaqAction(formData, ivTherapyId));
      dispatch(getAllFaqsAction(ivTherapyId));
      handleClose();
    }
  }

  return (
    <DashboardLayout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/backend/dashboard">
          Home
        </Link>
        {/* <Link
          underline="hover"
          color="inherit"
          href="/backend/treatments"
        >
          {treatment.title}
        </Link> */}
        <Typography color="text.primary">Faq</Typography>
      </Breadcrumbs>
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Faq
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadCause}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label1">Show in Main Page</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Popular"
                        value={isShow}
                        onChange={handleChange}
                      >
                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Question</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={question} onChange={(e) => setQuestion(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Answer</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={answer} onChange={(e) => setAnswer(e.target.value)} />
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Faqs
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Options;

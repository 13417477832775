import { GET_ALL_BODY_PART_SYMPTOMS, GET_SINGLE_BODY_PART_SYMPTOM, EDIT_BODY_PART_SYMPTOM, SAVE_BODY_PART_SYMPTOM, DELETE_BODY_PART_SYMPTOM, SAVE_BODY_PART_SYMPTOM_DISEASE } from "../actionType";

const initialState = {
  symptoms: [],
  symptom: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BODY_PART_SYMPTOMS:
      return {
        ...state,
        symptoms: action.payload,
      };
    case GET_SINGLE_BODY_PART_SYMPTOM:
      return {
        ...state,
        symptom: action.payload,
      };

    case EDIT_BODY_PART_SYMPTOM:
      return {
        ...state,
      };

    case SAVE_BODY_PART_SYMPTOM:
      return {
        ...state,
      };

    case DELETE_BODY_PART_SYMPTOM:
      return {
        ...state,
      };

    case SAVE_BODY_PART_SYMPTOM_DISEASE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

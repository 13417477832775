import { GET_ALL_TEMPLATE, EDIT_TEMPLATE, SAVE_TEMPLATE, DELETE_TEMPLATE } from "../actionType";

const initialState = {
  templates: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TEMPLATE:
      return {
        ...state,
        templates: action.payload,
      };
    case EDIT_TEMPLATE:
      return {
        ...state,
      };

    case SAVE_TEMPLATE:
      return {
        ...state,
      };

    case DELETE_TEMPLATE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_CITY_TREATMENT_BEFORE_AFTER, EDIT_CITY_TREATMENT_BEFORE_AFTER, SAVE_CITY_TREATMENT_BEFORE_AFTER, DELETE_CITY_TREATMENT_BEFORE_AFTER } from "../actionType";
import { axiosInstance, getAllCityTreatmentBeforeAfter, addCityTreatmentBeforeAfter, editCityTreatmentBeforeAfter, deleteCityTreatmentBeforeAfter } from "../../services/Apis";

export const getAllBeforeAftersAction = (cityId, treatmentId) => (dispatch) => {
  //console.log('ff',cityId, treatmentId)
  axiosInstance
    .get(getAllCityTreatmentBeforeAfter+"/"+cityId+"/"+treatmentId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchTechnique);
      dispatch({
        type: GET_ALL_CITY_TREATMENT_BEFORE_AFTER,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editBeforeAfterAction = (cityId, treatmentId, formData, beforeAfterId) => (dispatch) => {
  axiosInstance
  .put(editCityTreatmentBeforeAfter+"/"+beforeAfterId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_CITY_TREATMENT_BEFORE_AFTER,
      payload: response.data,
    });
  })
  .then(dispatch(getAllBeforeAftersAction(cityId, treatmentId)))
  .catch((error) => console.log(error));
};

export const saveBeforeAfterAction = (cityId, treatmentId, formData) => (dispatch) => {
  axiosInstance
  .post(addCityTreatmentBeforeAfter, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_CITY_TREATMENT_BEFORE_AFTER,
      payload: response.data,
    });
  })
  .then(dispatch(getAllBeforeAftersAction(cityId, treatmentId)))
  .catch((error) => console.log(error));
};

export const deleteBeforeAfterAction = (cityId, treatmentId, beforeAfterId) => (dispatch) => {
  axiosInstance
  .delete(deleteCityTreatmentBeforeAfter+"/"+beforeAfterId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_CITY_TREATMENT_BEFORE_AFTER,
      payload: response.data,
    });
  })
  .then(dispatch(getAllBeforeAftersAction(cityId, treatmentId)))
  .catch((error) => console.log(error));
};

import { useState, useEffect } from "react";
import axios from "axios";
import { ApiMainUrl } from '../../../config';
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import CircularProgress from '@mui/material/CircularProgress';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

//actions
// import { setAuthentication } from '../../../store/Authentication/action';

function Signin() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [mobile, setMobile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setMobile(e.target.value);
  }

  useEffect(() => {
    axios.get("https://api.ipregistry.co/?key=3228jir33m5fph17")
      .then(result => {
        setCountryCode(result.data.location.country.code);
      })
  }, []);


  const handleSubmit = () => {
    setIsLoading(true);
    const data = {
      'mobile': mobile,
      "role_id": 3
    };
    axios.post(ApiMainUrl + "api/auth/login", data)
      .then(result => {
        //setLoading(false);
        //console.log(result.data);
        // sessionStorage.setItem("userToken", result.data.token);
        // dispatch(setAuthentication(true));
        // navigate("/body-parts");
        // if(result.data.status == "Success"){

        // }
        //console.log(result);
        setIsLoading(false);
        if (result.data.status == 1) {
          navigate("/verify", { state: { userId: result.data.user_id } });
          setMobile("");
        }else{
          setErrorMessage(result.data.message)
        }
      })
      .catch(errors => {
        //setLoading(false);
        console.log(errors);
        //CacheStorage.setItem("userToken", result.data.token);
        //navigate("/dashboard");
        // if (errors.response.status === 400) {
        //   setErrorMsg(errors.response.data.message);
        //   //responseMessage("error", errors.response.data.message, "");
        // } else {
        //   //responseMessage("error", "Sorry there have some issue", "");
        // }       
      });

  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        {/* <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          className="c-signin-heading"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox> */}
        <MDBox className="c-signin-page" pt={4} pb={3} px={3}>
          <MDTypography textAlign="center" variant="h4" fontWeight="medium" color="black" mb={3}>
            Sign in
          </MDTypography>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              {/* <MDInput type="tel" id="phone" label="Phone" value={mobile} fullWidth onChange={handleChange} /> */}
              <PhoneInput
                placeholder='Enter mobile number'
                international
                countryCallingCodeEditable={false}
                defaultCountry={countryCode}
                value={mobile}
                onChange={setMobile}
                className='form-control'
              />
            </MDBox>           
            {/* <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth />
            </MDBox> */}
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <Box sx={{ m: 1, position: 'relative' }}>
                <MDButton variant="gradient" color="info" disabled={isLoading} onClick={handleSubmit} fullWidth>
                  Verify
                </MDButton>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            </MDBox>
            <p style={{textAlign:"center", fontSize:14, color:"red", marginBottom:0}}>{errorMessage}</p>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Signin;

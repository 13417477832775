/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import { accessKeyId, secretAccessKey, region } from '../../../config';
import S3 from "react-aws-s3";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup } from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import { getAllKeywordsAction, deleteKeywordAction } from '../../../store/KeywordManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.keyword);
  const dispatch = useDispatch();

  const [keywords, setKeywords] = useState([]);
  const [allKeywords, setAllKeywords] = useState([]);

  useEffect(() => {
    dispatch(getAllKeywordsAction());       
  }, []);

  useEffect(() => {
    //Lists
    
    if (state.keywords !== undefined) {
      //console.log(state.keywords);
      setKeywords(state.keywords);
    }
  }, [state]);

  const handleDeleteKeyword = (index) => {
    dispatch(deleteKeywordAction(keywords[index].keyword_id));   
    dispatch(getAllKeywordsAction());        
  }

  const handleEditKeyword = (keyword) => {
    //console.log(condition);
    props.setName(keyword.name);
    props.setKeywordId(keyword.keyword_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    //console.log(keywords);
    const allKeywords1 = keywords.map((keyword, index) => (
      {
        keywordName: keyword.name,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditKeyword(keyword)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteKeyword(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllKeywords(allKeywords1);
  }, [keywords]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Name", accessor: "keywordName", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allKeywords
  };
}

import { GET_ALL_MEDICINES, EDIT_MEDICINE, SAVE_MEDICINE, DELETE_MEDICINE } from "../actionType";
import { axiosInstance, getAllMedicines, addMedicine, editMedicine, deleteMedicine } from "../../services/Apis";

export const getAllMedicinesAction = () => (dispatch) => {
  axiosInstance
    .get(getAllMedicines, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchClinic);
      dispatch({
        type: GET_ALL_MEDICINES,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editMedicineAction = (formData, medicineId) => (dispatch) => {
  axiosInstance
  .put(editMedicine+"/"+medicineId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_MEDICINE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveMedicineAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addMedicine, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_MEDICINE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteMedicineAction = (medicineId) => (dispatch) => {
  axiosInstance
  .delete(deleteMedicine+"/"+medicineId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_MEDICINE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

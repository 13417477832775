/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';
import S3 from "react-aws-s3";
import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../../config';
import { Link } from "react-router-dom";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllDoctorMembershipActions, deleteDoctorMembershipAction } from '../../../store/DoctorMembershipManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.doctorMembership);
  const dispatch = useDispatch();

  const [doctorMemberships, setDoctorMemberships] = useState([]);
  const [allDoctorMemberships, setAllDoctorMemberships] = useState([]);

  useEffect(() => {
    dispatch(getAllDoctorMembershipActions(props.doctorId));       
  }, []);

  useEffect(() => {
    //Lists
    if (state.doctorMemberships !== undefined) {
      setDoctorMemberships(state.doctorMemberships);
    }
  }, [state]);

  const handleDeleteDoctorMembership = (index) => {
    const config = {
      bucketName : bucketName,
      dirName : "doctorMembership",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    ReactS3Client
    .deleteFile(s3Url+"/doctorMembership/"+doctorMemberships[index].upload_certificate)
    .then((response) => {
      dispatch(deleteDoctorMembershipAction(doctorMemberships[index].membership_id));   
      dispatch(getAllDoctorMembershipActions(props.doctorId));
    })
    .catch(err => console.error(err))
  }

  const handleEditDoctorMembership = (membership) => {
    //console.log(cause);
    props.setName(membership.name);
    props.setShowName(membership.upload_certificate);
    props.setdoctorMembershipId(membership.membership_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    
    const alldoctorMembership1 = doctorMemberships.map((doctorMembership, index) => (
      {
        doctorMembershipCertificate: <a href={s3Url+"/doctorMembership/"+doctorMembership.upload_certificate} target="_blank"><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Certificate</MDButton></a>,
        doctorMembershipName: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {doctorMembership.name}
        </MDTypography>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditDoctorMembership(doctorMembership)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteDoctorMembership(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllDoctorMemberships(alldoctorMembership1);
  }, [doctorMemberships]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Certificate", accessor: "doctorMembershipCertificate", width: "30%", align: "left" },
      { Header: "Name", accessor: "doctorMembershipName", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allDoctorMemberships
  };
}
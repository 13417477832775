import React, { useState, useRef } from 'react';

import S3 from "react-aws-s3";

import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../config';

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import medicineTableData from "layouts/medicine/data/medicineTableData";

import { useDispatch, useSelector } from 'react-redux';
import { saveMedicineAction, editMedicineAction, getAllMedicinesAction } from '../../store/MedicineManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Medicines() {
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  const [file, setFile] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [showName, setShowName] = React.useState("");
  const [s3fileUrl, setS3FileUrl] = React.useState("");

  //Form Data
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [medicineUrl, setMedicineUrl] = useState("");
  const [medicineId, setMedicineId] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    setPrice("");
    setDescription("");
    setMedicineUrl("");
    setMedicineId("");
    setShowName("");
    setShowActive(false);
    setOpen(false);
  };

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g,"");  
    var random = ("" + Math.random()).substring(2, 8); 
    var random_number = timestamp+random;  
    return random_number;
  }

  const fileInput = useRef();

  const { columns, rows } = medicineTableData({handleOpen, setName, setPrice, setDescription, setMedicineUrl, setShowName, setShowActive, setMedicineId});
  const handleFileInput = (e) => {
    // handle validations
    //alert(1);
    setFile(e.target.files[0]);
    setFileName(getRandomFileName()+e.target.files[0].name);
  }

  const uploadMedicine = (event) => {
    event.preventDefault();
    const config = {
      bucketName : bucketName,
      dirName : "medicine",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (medicineId)
    {
      if(fileName != "")
      {
        ReactS3Client
        .deleteFile(s3Url+"/medicine/"+showName)
        .then((response) => {
          ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            setS3FileUrl(data.location);
            const formData = {
              "name" : name,
              "price" : price,
              "description" : description,
              "medicine_url" : medicineUrl,
              "upload_image" : fileName
            }
            dispatch(editMedicineAction(formData, medicineId));
            setMedicineId("");
            dispatch(getAllMedicinesAction()); 
            handleClose();
          })
        })
        .catch(err => console.error(err))
      }else{
        const formData = {
          "name" : name,
          "price" : price,
          "description" : description,
          "medicine_url" : medicineUrl
        }
        dispatch(editMedicineAction(formData, medicineId));
        setMedicineId("");
        dispatch(getAllMedicinesAction()); 
        handleClose();
      }
    }else{
      if(fileName != "")
      {
        ReactS3Client
        .deleteFile(s3Url+"/medicine/"+showName)
        .then((response) => {
          ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            setS3FileUrl(data.location);
            const formData = {
              "name" : name,
              "price" : price,
              "description" : description,
              "medicine_url" : medicineUrl,
              "upload_image" : fileName
            }
            dispatch(saveMedicineAction(formData));
            dispatch(getAllMedicinesAction()); 
            handleClose();
          })
        })
        .catch(err => console.error(err))
      }else{
        const formData = {
          "name" : name,
          "price" : price,
          "description" : description,
          "medicine_url" : medicineUrl
        }
        dispatch(saveMedicineAction(formData));
        dispatch(getAllMedicinesAction()); 
        handleClose();
      }
    }
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Medicine
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadMedicine}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Name</InputLabel>
                      <Input id="title" value={name} aria-describedby="my-helper-text" onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Price</InputLabel>
                      <Input id="title" value={price} aria-describedby="my-helper-text" onChange={(e) => setPrice(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Description</InputLabel>
                      <Input id="description" value={description} aria-describedby="my-helper-text" onChange={(e) => setDescription(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Medicine URL</InputLabel>
                      <Input id="medicineUrl" value={medicineUrl} aria-describedby="my-helper-text" onChange={(e) => setMedicineUrl(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Image</InputLabel>
                      <Box component="img" src={s3Url+"/medicine/"+encodeURI(showName)}></Box>
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                Medicines
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Medicines;

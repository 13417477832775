import { GET_ALL_MAJOR_CATEGORYS, EDIT_MAJOR_CATEGORY, SAVE_MAJOR_CATEGORY, DELETE_MAJOR_CATEGORY } from "../actionType";
import { axiosInstance, getAllMajorCategorys, addMajorCategory, editMajorCategory, deleteMajorCategory } from "../../services/Apis";

export const getAllMajorCategorysAction = () => (dispatch) => {
  axiosInstance
    .get(getAllMajorCategorys, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchCause);
      dispatch({
        type: GET_ALL_MAJOR_CATEGORYS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editMajorCategoryAction = (formData, majorCategoryId) => (dispatch) => {
  axiosInstance
  .put(editMajorCategory+"/"+majorCategoryId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_MAJOR_CATEGORY,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveMajorCategoryAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addMajorCategory, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_MAJOR_CATEGORY,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteMajorCategoryAction = (majorCategoryId) => (dispatch) => {
  axiosInstance
  .delete(deleteMajorCategory+"/"+majorCategoryId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_MAJOR_CATEGORY,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

import { GET_ALL_THERAPY, EDIT_THERAPY, SAVE_THERAPY, DELETE_THERAPY } from "../actionType";
import { axiosInstance, getAllTherapy, addTherapy, editTherapy, deleteTherapy } from "../../services/Apis";

export const getAllTherapyAction = () => (dispatch) => {
  axiosInstance
    .get(getAllTherapy, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchDoctor[0]);
      dispatch({
        type: GET_ALL_THERAPY,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};


export const editTherapyAction = (doctorId, data) => (dispatch) => {
  axiosInstance
  .put(editTherapy+"/"+doctorId, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_THERAPY,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveTherapyAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addTherapy, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_THERAPY,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteTherapyAction = (doctorId) => (dispatch) => {
  axiosInstance
  .delete(deleteTherapy+"/"+doctorId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_THERAPY,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

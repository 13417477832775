import { GET_ALL_SYMPTOM, GET_SINGLE_SYMPTOM, EDIT_SYMPTOM, SAVE_SYMPTOM, DELETE_SYMPTOM } from "../actionType";
import { axiosInstance, getAllSymptom, addSymptom, editSymptom, deleteSymptom } from "../../services/Apis";

export const getAllSymptomAction = (bodyPartId) => (dispatch) => {
  axiosInstance
    .get(getAllSymptom+"/"+bodyPartId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchCause);
      dispatch({
        type: GET_ALL_SYMPTOM,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const getSingleSymptomAction = (symptomId) => (dispatch) => {
  axiosInstance
    .get(getAllSymptom+"/"+symptomId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.data[0]);
      dispatch({
        type: GET_SINGLE_SYMPTOM,
        payload: response.data.data[0],
      });
    })
    .catch((error) => console.log(error));
};

export const editSymptomAction = (formData, symptomId) => (dispatch) => {
  axiosInstance
  .put(editSymptom+"/"+symptomId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_SYMPTOM,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveSymptomAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addSymptom, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_SYMPTOM,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteSymptomAction = (symptomId) => (dispatch) => {
  axiosInstance
  .delete(deleteSymptom+"/"+symptomId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_SYMPTOM,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

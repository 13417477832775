import { GET_ALL_MEDICINES, EDIT_MEDICINE, SAVE_MEDICINE, DELETE_MEDICINE } from "../actionType";

const initialState = {
  medicines: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MEDICINES:
      return {
        ...state,
        medicines: action.payload,
      };

    case EDIT_MEDICINE:
      return {
        ...state,
      };

    case SAVE_MEDICINE:
      return {
        ...state,
      };

    case DELETE_MEDICINE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_POST, EDIT_POST, SAVE_POST, DELETE_POST} from "../actionType";
import { axiosInstance, getAllBlog, addBlog, editBlog, deleteBlog } from "../../services/Apis";

export const getAllBlogAction = (categoryId) => (dispatch) => {
  axiosInstance
    .get(getAllBlog, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchCause);
      dispatch({
        type: GET_ALL_POST,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editBlogAction = (formData, categoryId) => (dispatch) => {
  axiosInstance
  .put(editBlog+"/"+categoryId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_POST,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveBlogAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addBlog, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_POST,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteBlogAction = (postId) => (dispatch) => {
  // console.log('ddddd', postId)
  axiosInstance
  .delete(deleteBlog+"/"+postId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_POST,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

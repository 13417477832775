import React, { useState, useEffect, useRef } from 'react';
import S3 from "react-aws-s3";
import { accessKeyId, secretAccessKey, region, bucketName, s3Url } from '../../config';

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, TextField, FormGroup } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import locationTableData from "layouts/location/data/locationTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getAllLocationsAction, getAllActiveLocationsAction, saveLocationAction, editLocationAction } from '../../store/LocationManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Locations() {
  const dispatch = useDispatch();
  //Form Data
  const [showActive, setShowActive] = React.useState(false);
  const [showName, setShowName] = React.useState("");
  const [name, setName] = useState("");
  const [googleMap, setGoogleMap] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [locationId, setLocationId] = useState("");
  const [file, setFile] = React.useState([]);
  const [fileName, setFileName] = React.useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    setGoogleMap("");
    setBusinessName("");
    setAddress1("");
    setAddress2("");
    setCity("");
    setState("");
    setZipCode("");
    setPhone("");
    setLocationId("");
    setOpen(false);
  };

  const { columns, rows } = locationTableData({ handleOpen, setName, setGoogleMap, setBusinessName, setAddress1, setAddress2, setCity, setState, setZipCode, setPhone, setLocationId, setShowActive, setShowName });

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    var random = ("" + Math.random()).substring(2, 8);
    var random_number = timestamp + random;
    return random_number;
  };

  const fileInput = useRef();

  const handleFileInput = (e) => {
    // handle validations
    //alert(1);
    setFile(e.target.files[0]);
    setFileName(getRandomFileName() + e.target.files[0].name);
  };


  const uploadLocation = (event) => {
    event.preventDefault();
    const config = {
      bucketName: bucketName,
      dirName: "location",
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (locationId) {
      if (fileName != "") {
        ReactS3Client
          .deleteFile(s3Url + "/location/" + showName)
          .then((response) => {
            ReactS3Client.uploadFile(file, fileName)
              .then(data => {
                //console.log(data.location);
                //setS3FileUrl(data.location);
                const formData = {
                  "title": name,
                  "google_map": googleMap,
                  "business_name": businessName,
                  "address_1": address1,
                  "address_2": address2,
                  "city": city,
                  "state": state,
                  "zip": zipcode,
                  "phone": phone,
                  "upload_image": fileName
                }
                dispatch(editLocationAction(formData, locationId));
                setLocationId("");
                dispatch(getAllLocationsAction());
                handleClose();
              })
          })
          .catch(err => console.error(err))
      } else {
        const formData = {
          "title": name,
          "google_map": googleMap,
          "business_name": businessName,
          "address_1": address1,
          "address_2": address2,
          "city": city,
          "state": state,
          "zip": zipcode,
          "phone": phone,
        }
        dispatch(editLocationAction(formData, locationId));
        dispatch(getAllLocationsAction());
        handleClose();
      }
    } else {
      if (fileName != "") {
        ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            //setS3FileUrl(data.location);
            const formData = {
              "title": name,
              "google_map": googleMap,
              "business_name": businessName,
              "address_1": address1,
              "address_2": address2,
              "city": city,
              "state": state,
              "zip": zipcode,
              "phone": phone,
              "upload_image": fileName
            }
            dispatch(saveLocationAction(formData));
            dispatch(getAllLocationsAction());
            handleClose();
          })
      } else {
        const formData = {
          "title": name,
          "google_map": googleMap,
          "business_name": businessName,
          "address_1": address1,
          "address_2": address2,
          "city": city,
          "state": state,
          "zip": zipcode,
          "phone": phone,
        }
        dispatch(saveLocationAction(formData));
        dispatch(getAllLocationsAction());
        handleClose();
      }
    }
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Location
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadLocation}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Google Map</InputLabel>
                      <Input
                        id="google-map"
                        multiline
                        maxRows={4}
                        value={googleMap}
                        onChange={(e) => setGoogleMap(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Business Name</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Address 1</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={address1} onChange={(e) => setAddress1(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Address 2</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">City</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={city} onChange={(e) => setCity(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">State</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={state} onChange={(e) => setState(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Zip Code</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={zipcode} onChange={(e) => setZipCode(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Phone</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Banner Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={showActive ? "show" : "hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Image</InputLabel>
                      <Box component="img" src={s3Url + "/location/" + showName}></Box>
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Locations
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Locations;

import { GET_ALL_VIDEOS, EDIT_VIDEO, SAVE_VIDEO, DELETE_VIDEO } from "../actionType";

const initialState = {
  videos: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_VIDEOS:
      return {
        ...state,
        videos: action.payload,
      };

    case EDIT_VIDEO:
      return {
        ...state,
      };

    case SAVE_VIDEO:
      return {
        ...state,
      };

    case DELETE_VIDEO:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

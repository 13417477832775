import { GET_ALL_CITY_TREATMENT_FAQ, EDIT_CITY_TREATMENT_FAQ, SAVE_CITY_TREATMENT_FAQ, DELETE_CITY_TREATMENT_FAQ } from "../actionType";
import { axiosInstance, getAllCityTreatmentFaq, addCityTreatmentFaq, editCityTreatmentFaq, deleteCityTreatmentFaq } from "../../services/Apis";

export const getAllVideosAction = (treatmentId, locationId) => (dispatch) => {
  console.log("loca",locationId);
  console.log("trest",treatmentId);
  axiosInstance
    .get(getAllCityTreatmentFaq+"/"+locationId+"/"+treatmentId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      dispatch({
        type: GET_ALL_CITY_TREATMENT_FAQ,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editVideoAction = (formData, videoId, treatmentId, locationId) => (dispatch) => {
  axiosInstance
  .put(editCityTreatmentFaq+"/"+videoId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_CITY_TREATMENT_FAQ,
      payload: response.data,
    });

    if(response.data.status == true)
    {
      console.log(response.data.status);
      getAllVideosAction(treatmentId);
    }
  })
  .then(dispatch(getAllVideosAction(treatmentId, locationId)))
  .catch((error) => console.log(error));
};

export const saveVideoAction = (formData, treatmentId, locationId) => (dispatch) => {
  axiosInstance
  .post(addCityTreatmentFaq, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_CITY_TREATMENT_FAQ,
      payload: response.data,
    });
  })
  .then(dispatch(getAllVideosAction(treatmentId, locationId)))
  .catch((error) => console.log(error));
};

export const deleteVideoAction = (videoId, treatmentId, locationId) => (dispatch) => {
  axiosInstance
  .delete(deleteCityTreatmentFaq+"/"+videoId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_CITY_TREATMENT_FAQ,
      payload: response.data,
    });
    if(response.data.status == true)
    {
      getAllVideosAction(treatmentId);
    }
  })
  .then(dispatch(getAllVideosAction(treatmentId, locationId)))
  .catch((error) => console.log(error));
};

import { GET_ALL_TREATMENTS, GET_ALL_TREATMENT_BODYPARTS, GET_SINGLE_TREATMENT, EDIT_TREATMENT, SAVE_TREATMENT, DELETE_TREATMENT } from "../actionType";
import { axiosInstance, getAllTreatments, getAllTreatmentBodyparts, addTreatment, editTreatment, deleteTreatment } from "../../services/Apis";

export const getAllTreatmentsAction = () => (dispatch) => {
  axiosInstance
    .get(getAllTreatments, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchTreatment);
      dispatch({
        type: GET_ALL_TREATMENTS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const getAllTreatmentBodypartsAction = () => (dispatch) => {
  axiosInstance
    .get(getAllTreatmentBodyparts, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchTreatment);
      dispatch({
        type: GET_ALL_TREATMENT_BODYPARTS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const getSingleTreatmentAction = (treatmentId) => (dispatch) => {
  axiosInstance
    .get(getAllTreatments+"/"+treatmentId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchTreatment);
      dispatch({
        type: GET_SINGLE_TREATMENT,
        payload: response.data.data[0],
      });
    })
    .catch((error) => console.log(error));
};

export const editTreatmentAction = (formData, treatmentId) => (dispatch) => {
  axiosInstance
  .put(editTreatment+"/"+treatmentId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_TREATMENT,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveTreatmentAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addTreatment, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_TREATMENT,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteTreatmentAction = (TreatmentId) => (dispatch) => {
  axiosInstance
  .delete(deleteTreatment+"/"+TreatmentId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_TREATMENT,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

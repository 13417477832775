import { GET_ALL_MAJOR_CATEGORYS, EDIT_MAJOR_CATEGORY, SAVE_MAJOR_CATEGORY, DELETE_MAJOR_CATEGORY } from "../actionType";

const initialState = {
  categories: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MAJOR_CATEGORYS:
      return {
        ...state,
        categories: action.payload,
      };

    case EDIT_MAJOR_CATEGORY:
      return {
        ...state,
      };

    case SAVE_MAJOR_CATEGORY:
      return {
        ...state,
      };

    case DELETE_MAJOR_CATEGORY:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

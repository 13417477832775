/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import { accessKeyId, secretAccessKey, region } from '../../../config';
import S3 from "react-aws-s3";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup } from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import { getAllNewsletterAction, deleteNewsletterAction, } from '../../../store/NewsletterManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.newsletter);
  const dispatch = useDispatch();

  const [newsletter, setNewsletter] = useState([]);
  const [allNewsletters, setAllNewsletters] = useState([]);

  useEffect(() => {
    dispatch(getAllNewsletterAction());       
  }, [dispatch]);

  useEffect(() => {
    //Lists
    
    if (state.newsletters !== undefined) {
      setNewsletter(state.newsletters);
    }
  }, [state]);

  const handleDeleteNewsletter = (index) => {
    dispatch(deleteNewsletterAction(newsletter[index].newsletter_id));   
    dispatch(getAllNewsletterAction());        
  }

  const handleEditNewsletter = (newsletter) => {
    //console.log(condition);
    props.setYear(newsletter.year);
    props.setTitle(newsletter.title);
    props.setDescription(newsletter.description);
    props.setNewsletterId(newsletter.newsletter_id);
    props.setShowActive(true);
    props.setNewsletterLink(newsletter.newsletter_link);
    props.setShowName(newsletter.upload_image);
    props.handleOpen();
  };

  useEffect(() => {
    const allnewsletter1 = newsletter.map((newsletter, index) => (
      {
        NewsletterName: newsletter.title,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditNewsletter(newsletter)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteNewsletter(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllNewsletters(allnewsletter1);
  }, [newsletter]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Newsletter Name", accessor: "NewsletterName", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allNewsletters
  };
}

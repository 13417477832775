import { GET_ALL_FAQS, EDIT_FAQ, SAVE_FAQ, DELETE_FAQ } from "../actionType";

const initialState = {
  faqs: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FAQS:
      return {
        ...state,
        faqs: action.payload,
      };

    case EDIT_FAQ:
      return {
        ...state,
      };

    case SAVE_FAQ:
      return {
        ...state,
      };

    case DELETE_FAQ:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_PAGES, EDIT_PAGES, SAVE_PAGES, DELETE_PAGES } from "../actionType";

const initialState = {
  allPages: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PAGES:
      return {
        ...state,
        allPages: action.payload,
      };

    case EDIT_PAGES:
      return {
        ...state,
      };

    case SAVE_PAGES:
      return {
        ...state,
      };

    case DELETE_PAGES:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

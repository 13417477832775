import { GET_ALL_THERAPY_INGREDIENT, EDIT_THERAPY_INGREDIENT, SAVE_THERAPY_INGREDIENT, DELETE_THERAPY_INGREDIENT } from "../actionType";

const initialState = {
  therapyIngredients: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_THERAPY_INGREDIENT:
      return {
        ...state,
        therapyIngredients: action.payload,
      };

    case EDIT_THERAPY_INGREDIENT:
      return {
        ...state,
      };

    case SAVE_THERAPY_INGREDIENT:
      return {
        ...state,
      };

    case DELETE_THERAPY_INGREDIENT:
      return {
        ...state,
     };

    default:
      return state;
  }
};

export default reducer;

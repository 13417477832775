import { GET_ALL_BODY_PARTS, GET_SINGLE_BODY_PART, EDIT_BODY_PART, SAVE_BODY_PART, DELETE_BODY_PART } from "../actionType";
import { axiosInstance, getAllBodyParts, addBodyPart, editBodyPart, deleteBodyPart } from "../../services/Apis";

export const getAllBodyPartsAction = () => (dispatch) => {
  axiosInstance
    .get(getAllBodyParts, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchBodyPart);
      dispatch({
        type: GET_ALL_BODY_PARTS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const getSingleBodyPartAction = (bodyPartId) => (dispatch) => {
  axiosInstance
    .get(getAllBodyParts+"/"+bodyPartId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchTreatment);
      dispatch({
        type: GET_SINGLE_BODY_PART,
        payload: response.data.data[0],
      });
    })
    .catch((error) => console.log(error));
};

export const editBodyPartAction = (data, bodypartId) => (dispatch) => {
  axiosInstance
  .put(editBodyPart+"/"+bodypartId, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_BODY_PART,
      payload: response.data,
    });
  })
  .then(dispatch(getAllBodyPartsAction()))
  .catch((error) => console.log(error));
};

export const saveBodyPartAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addBodyPart, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_BODY_PART,
      payload: response.data,
    });
  })
  .then(dispatch(getAllBodyPartsAction()))
  .catch((error) => console.log(error));
};

export const deleteBodyPartAction = (BodyPartId) => (dispatch) => {
  axiosInstance
  .delete(deleteBodyPart+"/"+BodyPartId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_BODY_PART,
      payload: response.data,
    });
  })
  .then(dispatch(getAllBodyPartsAction()))
  .catch((error) => console.log(error));
};

import React, { useState, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { accessKeyId, secretAccessKey, region, s3Url, editorKey } from '../../config';
import { useParams } from "react-router-dom";
// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, Typography, Breadcrumbs, Link } from '@mui/material';
//import Breadcrumbs from "examples/Breadcrumbs";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import testimonialTableData from "layouts/ivTherapyTestimonial/data/testimonialTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getSingleTreatmentAction } from '../../store/TreatmentManagement/action';
import { getAllTestimonialsAction, saveTestimonialAction, editTestimonialAction } from '../../store/IvTherapyTestimonialManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Options() {
  let { treatmentId } = useParams();
  // console.log('sda', treatmentId)
  const state = useSelector(state => state.treatment);

  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  //Form Data
  const [name, setName] = useState("");
  const [rating, setRating] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [testimonial1, setTestimonial1] = useState("");
  const [treatment, setTreatment] = useState({});
  const [testimonialId, setTestimonialId] = useState("");
  const [isShow, setIsShow] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    setRating("");
    setTestimonial("");
    setTestimonial1("");
    setTestimonialId("");
    setShowActive(false);
    setIsShow(false);
    setOpen(false);
  };

  const { columns, rows } = testimonialTableData({ treatmentId, handleOpen, setName, setRating, setTestimonial, setTestimonial1, setTestimonialId, setShowActive, setIsShow });

  // useEffect(() => {
  //   dispatch(getSingleTreatmentAction(treatmentId));       
  // }, [treatmentId]);

  useEffect(() => {
    //Lists
    if (state.treatment !== undefined) {
      setTreatment(state.treatment);
      //console.log(state.treatment);
    }
  }, [state]);

  const handleRatingChange = (event) => {
    setRating(event.target.value);
  };

  const handleChange = (event) => {
    setIsShow(event.target.value);
  };

  const uploadTestimonial = (event) => {
    event.preventDefault();
    if (testimonialId) {
      const formData = {
        "name": name,
        "rating": rating,
        "testimonial": testimonial,
        "is_ivtherapy_page": isShow,
        "ivtherapy_id": treatmentId
      }
      dispatch(editTestimonialAction(formData, testimonialId, treatmentId));
      setTestimonialId("");
      dispatch(getAllTestimonialsAction(treatmentId));
      handleClose();
    } else {
      const formData = {
        "name": name,
        "rating": rating,
        "testimonial": testimonial,
        "is_ivtherapy_page": isShow,
        "ivtherapy_id": treatmentId
      }
      dispatch(saveTestimonialAction(formData, treatmentId));
      dispatch(getAllTestimonialsAction(treatmentId));
      handleClose();
    }
  }

  return (
    <DashboardLayout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/backend/dashboard">
          Home
        </Link>
        {/* <Link
          underline="hover"
          color="inherit"
          href="/backend/treatments"
        >
          {treatment.title}
        </Link> */}
        <Typography color="text.primary">Testimonial</Typography>
      </Breadcrumbs>
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Testimonial
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
            disableAutoFocus={true} //for disable modal
            disableEnforceFocus={true} //for disable modal
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadTestimonial}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label1">Show in Main Page</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Popular"
                        value={isShow}
                        onChange={handleChange}
                      >
                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Name</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="rating-label">Rating</InputLabel>
                      <Select
                        labelId="rating-label"
                        id="demo-simple-select"
                        label="Rating"
                        value={rating}
                        onChange={handleRatingChange}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Testimonial</InputLabel>
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(value) => setTestimonial(value)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        value={testimonial}
                        // init={{
                        //   height: 500,
                        //   menubar: true,
                        //   plugins: [
                        //     'advlist autolink lists link image charmap print preview anchor',
                        //     'searchreplace visualblocks code fullscreen',
                        //     'insertdatetime media table paste code help wordcount'
                        //   ],
                        //   toolbar: 'undo redo | formatselect | ' +
                        //   'bold italic backcolor | alignleft aligncenter ' +
                        //   'alignright alignjustify | bullist numlist outdent indent | ' +
                        //   'removeformat | help',
                        //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        //   apiKey : 'syi46egl19ax4jd173sv2vb56nfloqubu47m5qlo6wikqo1d'
                        // }}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Testimonials
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Options;

import { GET_ALL_CATEGORY, EDIT_CATEGORY, SAVE_CATEGORY, DELETE_CATEGORY } from "../actionType";
import { axiosInstance, getAllCategory, addCategory, editCategory, deleteCategory } from "../../services/Apis";

export const getAllCategorysAction = () => (dispatch) => {
  axiosInstance
    .get(getAllCategory, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchClinic);
      dispatch({
        type: GET_ALL_CATEGORY,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editCategoryAction = (formData, CategoryId) => (dispatch) => {
  axiosInstance
  .put(editCategory+"/"+CategoryId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_CATEGORY,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveCategoryAction = (formData) => (dispatch) => {
  console.log("save", formData);
  axiosInstance
  .post(addCategory, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_CATEGORY,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteCategoryAction = (CategoryId) => (dispatch) => {
  axiosInstance
  .delete(deleteCategory+"/"+CategoryId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_CATEGORY,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

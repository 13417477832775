import { GET_ALL_CITY_TREATMENTS, EDIT_CITY_TREATMENT, SAVE_CITY_TREATMENT, DELETE_CITY_TREATMENT } from "../actionType";

const initialState = {
  cityTreatments: [],
  treatment: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CITY_TREATMENTS:
      return {
        ...state,
        cityTreatments: action.payload,
        treatment: action.payload.treatment
      };

    case EDIT_CITY_TREATMENT:
      return {
        ...state,
      };

    case SAVE_CITY_TREATMENT:
      return {
        ...state,
      };

    case DELETE_CITY_TREATMENT:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import { s3Url } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllFaqsAction, deleteFaqAction } from '../../../store/IvTherapyFaqManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.ivFaq);
  const dispatch = useDispatch();

  const [faqs, setFaqs] = useState([]);
  const [allFaqs, setallFaqs] = useState([]);

  useEffect(() => {
    dispatch(getAllFaqsAction(props.ivTherapyId));       
  }, []);

  useEffect(() => {
    //Lists
    if (state.faqs !== undefined) {
      setFaqs(state.faqs);
    }
  }, [state]);  

  const handleDeleteFaq = (index) => {
    dispatch(deleteFaqAction(faqs[index].faq_id));   
    dispatch(getAllFaqsAction(props.ivTherapyId));
  }

  const handleEditFaq = (faq) => {
    console.log(faq);
    props.setQuestion(faq.question);
    props.setAnswer(faq.answer);
    props.setFaqId(faq.faq_id);
    props.setShowActive(true);
    props.setIsShow(faq.is_ivtherapy_page);
    props.handleOpen();
  };

  useEffect(() => {
    const faqs1 = faqs.map((faq, index) => (
      {
        faqQuestion: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {faq.question}
        </MDTypography>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditFaq(faq)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteFaq(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setallFaqs(faqs1);
  }, [faqs]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Question", accessor: "faqQuestion", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allFaqs
  };
}

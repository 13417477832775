/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import { s3Url, bucketName } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllTechniquesAction, deleteTechniqueAction } from '../../../store/TechniqueManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.technique);
  const dispatch = useDispatch();

  const [techniques, setTechniques] = useState([]);
  const [allTechniques, setAllTechniques] = useState([]);

  useEffect(() => {
    dispatch(getAllTechniquesAction(props.treatmentId));       
  }, []);

  useEffect(() => {
    //Lists
    if (state.techniques !== undefined) {
      setTechniques(state.techniques);
    }
  }, [state]);  

  const handleDeletetechnique = (index) => {
    const config = {
      bucketName : bucketName,
      dirName : "technique",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    ReactS3Client
    .deleteFile(s3Url+"/technique/"+techniques[index].upload_image)
    .then((response) => {
      dispatch(deleteTechniqueAction(techniques[index].technique_id));   
      dispatch(getAllTechniquesAction());
    })
    .catch(err => console.error(err))
  }

  const handleEdittechnique = (technique) => {
    //console.log(technique);
    props.setName(technique.name);
    props.setShowName(technique.upload_image);
    //props.setTreatmentId(technique.treatment_id);
    props.setTechniqueId(technique.technique_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    console.log(techniques);
    const alltechnique1 = techniques.map((technique, index) => (
      {
        techniqueImage: <Author image={s3Url+"/technique/"+technique.upload_image} name={technique.name} />,
        techniqueTitle: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {technique.name}
        </MDTypography>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEdittechnique(technique)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeletetechnique(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllTechniques(alltechnique1);
  }, [techniques]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Image", accessor: "techniqueImage", width: "30%", align: "left" },
      { Header: "Title", accessor: "techniqueTitle", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allTechniques
  };
}

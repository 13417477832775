import { GET_ALL_DOCTORS, GET_SINGLE_DOCTOR, EDIT_DOCTOR, SAVE_DOCTOR, DELETE_DOCTOR } from "../actionType";

const initialState = {
  doctors: [],
  doctor: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DOCTORS:
      return {
        ...state,
        doctors: action.payload,
      };

    case GET_SINGLE_DOCTOR:
      return {
        ...state,
        doctor: action.payload,
      };

    case EDIT_DOCTOR:
      return {
        ...state,
      };

    case SAVE_DOCTOR:
      return {
        ...state,
      };

    case DELETE_DOCTOR:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_CLINICS, EDIT_CLINIC, SAVE_CLINIC, DELETE_CLINIC } from "../actionType";
import { axiosInstance, getAllClinics, addClinic, editClinic, deleteClinic } from "../../services/Apis";

export const getAllClinicsAction = () => (dispatch) => {
  axiosInstance
    .get(getAllClinics, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchClinic);
      dispatch({
        type: GET_ALL_CLINICS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editClinicAction = (formData, ClinicId) => (dispatch) => {
  axiosInstance
  .put(editClinic+"/"+ClinicId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_CLINIC,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveClinicAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addClinic, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_CLINIC,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteClinicAction = (ClinicId) => (dispatch) => {
  axiosInstance
  .delete(deleteClinic+"/"+ClinicId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_CLINIC,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

import { GET_ALL_DOCTOR_EDUCATIONS, EDIT_DOCTOR_EDUCATION, SAVE_DOCTOR_EDUCATION, DELETE_DOCTOR_EDUCATION  } from "../actionType";
import { axiosInstance, getAllDoctorEducations, addDoctorEducation, editDoctorEducation, deleteDoctorEducation } from "../../services/Apis";

export const getAllDoctorEducationAction = (doctorId) => (dispatch) => {
  axiosInstance
    .get(getAllDoctorEducations+"/"+doctorId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchCause);
      dispatch({
        type: GET_ALL_DOCTOR_EDUCATIONS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editDoctorEducationAction = (formData, educationId) => (dispatch) => {
  axiosInstance
  .put(editDoctorEducation+"/"+educationId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_DOCTOR_EDUCATION,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveDoctorEducationAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addDoctorEducation, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_DOCTOR_EDUCATION,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteDoctorEducationAction = () => (dispatch) => {
  axiosInstance
  .delete(deleteDoctorEducation, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_DOCTOR_EDUCATION,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

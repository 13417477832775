import { GET_ALL_CITY_TREATMENT_VIDEO, EDIT_CITY_TREATMENT_VIDEO, SAVE_CITY_TREATMENT_VIDEO, DELETE_CITY_TREATMENT_VIDEO } from "../actionType";

const initialState = {
  videos: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CITY_TREATMENT_VIDEO:
      return {
        ...state,
        videos: action.payload,
      };

    case EDIT_CITY_TREATMENT_VIDEO:
      return {
        ...state,
      };

    case SAVE_CITY_TREATMENT_VIDEO:
      return {
        ...state,
      };

    case DELETE_CITY_TREATMENT_VIDEO:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

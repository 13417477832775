import { GET_ALL_CATEGORY, EDIT_CATEGORY, SAVE_CATEGORY, DELETE_CATEGORY } from "../actionType";

const initialState = {
  categorys: [],
  //likes: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORY:
      return {
        ...state,
        categorys: action.payload,
      };

    case EDIT_CATEGORY:
      return {
        ...state,
      };

    case SAVE_CATEGORY:
      return {
        ...state,
      };

    case DELETE_CATEGORY:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

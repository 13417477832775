import { GET_ALL_BODY_PARTS, GET_SINGLE_BODY_PART, EDIT_BODY_PART, SAVE_BODY_PART, DELETE_BODY_PART } from "../actionType";

const initialState = {
  bodyparts: [],
  bodyPart: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BODY_PARTS:
      return {
        ...state,
        bodyparts: action.payload,
      };
    case GET_SINGLE_BODY_PART:
      return {
        ...state,
        bodyPart: action.payload,
      };

    case EDIT_BODY_PART:
      return {
        ...state,
      };

    case SAVE_BODY_PART:
      return {
        ...state,
      };

    case DELETE_BODY_PART:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

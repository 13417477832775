/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';
import S3 from "react-aws-s3";
import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../../config';
import { Link } from "react-router-dom";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllDoctorEducationAction, deleteDoctorEducationAction } from '../../../store/DoctorEducationManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.doctorEducation);
  const dispatch = useDispatch();

  const [doctorEducations, setDoctorEducations] = useState([]);
  const [allDoctorEducations, setAllDoctorEducations] = useState([]);

  useEffect(() => {
    dispatch(getAllDoctorEducationAction(props.doctorId));       
  }, []);

  useEffect(() => {
    //Lists
    if (state.doctorEducations !== undefined) {
      setDoctorEducations(state.doctorEducations);
    }
  }, [state]);

  const handleDeleteDoctorEducation = (index) => {
    const config = {
      bucketName : bucketName,
      dirName : "doctorEducation",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    ReactS3Client
    .deleteFile(s3Url+"/doctorEducation"+doctorEducations[index].upload_certificate)
    .then((response) => {
      dispatch(deleteDoctorEducationAction(doctorEducations[index].education_id));   
      dispatch(getAllDoctorEducationAction(props.doctorId));
    })
    .catch(err => console.error(err))
  }

  const handleEditDoctorEducation = (education) => {
    //console.log(cause);
    props.setName(education.name);
    props.setShowName(education.upload_certificate);
    props.setDoctorEducationId(education.education_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    
    const allDoctorEducation1 = doctorEducations.map((doctorEducation, index) => (
      {
        doctorEducationCertificate: <a href={s3Url+"/doctorEducation/"+doctorEducation.upload_certificate} target="_blank"><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Certificate</MDButton></a>,
        doctorEducationName: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {doctorEducation.name}
        </MDTypography>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditDoctorEducation(doctorEducation)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteDoctorEducation(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllDoctorEducations(allDoctorEducation1);
  }, [doctorEducations]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Certificate", accessor: "doctorEducationCertificate", width: "30%", align: "left" },
      { Header: "Name", accessor: "doctorEducationName", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allDoctorEducations
  };
}

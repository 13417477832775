/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";

import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../../config';
import S3 from "react-aws-s3";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

// @mui material components

import {useDispatch, useSelector} from 'react-redux';
import { getAllBodyPartsAction, deleteBodyPartAction } from '../../../store/BodyPartManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.bodyPart);
  const dispatch = useDispatch();

  const [bodyparts, setBodyParts] = useState([]);
  const [allBodyParts, setAllBodyParts] = useState([]);

  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const config = {
    bucketName : bucketName,
    dirName : "bodyPart",
    region : region,
    accessKeyId : accessKeyId,
    secretAccessKey : secretAccessKey
  };

  useEffect(() => {
    dispatch(getAllBodyPartsAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (state.bodyparts !== undefined) {
      setBodyParts(state.bodyparts);
    }
  }, [state]);

  const handleDeleteBodyPart = (index) => {
    const active_image = bodyparts[index].active_image;
    const hover_image = bodyparts[index].hover_image;
    const ReactS3Client = new S3(config);

    ReactS3Client
    .deleteFile(active_image)
    .then((response) => {
      ReactS3Client
      .deleteFile(hover_image)
      .then((response) => {
        dispatch(deleteBodyPartAction(bodyparts[index].body_part_id));
      })
    })
    .catch(err => console.error(err))
        
  }

  const fileInput = useRef();

  const handleEditBodyPart = (bodypart) => {
    console.log(bodypart);
    props.setName(bodypart.name);
    props.setPopular(bodypart.popular);
    props.setIsAgeRestricted(bodypart.age_restriction);
    props.setDescription(bodypart.description);
    props.setRank(bodypart.rank);
    props.setShowImageName(bodypart.active_image);
    props.setShowHoverImageName(bodypart.hover_image);
    props.setShowBrownIconName(bodypart.brown_icon);
    props.setShowWhiteIconName(bodypart.white_icon);
    props.setMajorCategoryId(bodypart.major_category_id);
    props.setBodyPartId(bodypart.body_part_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    //console.log('asds',bodyparts)
    const allBodyParts1 = bodyparts.map((bodypart, index) => (
      {
        bodyPartImage: <Author image={s3Url+"/bodyPart/"+bodypart.active_image} name={bodypart.name} />,
        bodyPartHoverImage: <Author image={s3Url+"/bodyPart/"+bodypart.hover_image} name={bodypart.name} />,
        bodyPartTitle: bodypart.name,
        bodyPartRank:bodypart.rank,
        symptom: <Link to={"/symptom/"+bodypart.body_part_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Symptom</MDButton></Link>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditBodyPart(bodypart)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteBodyPart(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));

    //console.log('dad', allBodyParts1);
    setAllBodyParts(allBodyParts1);
  }, [bodyparts]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Body Part Image", accessor: "bodyPartImage", width: "30%", align: "left" },
      { Header: "Body Part Hover Image", accessor: "bodyPartHoverImage", width: "30%", align: "left" },
      { Header: "Body Part Title", accessor: "bodyPartTitle", width: "30%", align: "left" },
      { Header: "Body Part Rank", accessor: "bodyPartRank", width: "30%", align: "left" },
      { Header: "Symptom", accessor: "symptom", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allBodyParts
  };
}

import { GET_ALL_POST, EDIT_POST, SAVE_POST, DELETE_POST  } from "../actionType";

const initialState = {
  blogs: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_POST:
      return {
        ...state,
        blogs: action.payload
      };

    case EDIT_POST:
      return {
        ...state,
      };

    case SAVE_POST:
      return {
        ...state,
      };

    case DELETE_POST:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_BODY_PART_SYMPTOMS, GET_SINGLE_BODY_PART_SYMPTOM, EDIT_BODY_PART_SYMPTOM, SAVE_BODY_PART_SYMPTOM, DELETE_BODY_PART_SYMPTOM, SAVE_BODY_PART_SYMPTOM_DISEASE } from "../actionType";
import { axiosInstance, getAllBodyPartSymptoms, addBodyPartSymptom, editBodyPartSymptom, deleteBodyPartSymptom, addBodyPartSymptomDisease } from "../../services/Apis";

export const getAllBodyPartSymptomsAction = (bodyPartId) => (dispatch) => {
  axiosInstance
    .get(getAllBodyPartSymptoms+"/"+bodyPartId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchBodyPart);
      dispatch({
        type: GET_ALL_BODY_PART_SYMPTOMS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const getSingleBodyPartSymptomAction = (bodyPartId, id) => (dispatch) => {
  axiosInstance
    .get(getAllBodyPartSymptoms+"/"+bodyPartId+"/"+id, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchTreatment);
      dispatch({
        type: GET_SINGLE_BODY_PART_SYMPTOM,
        payload: response.data.data[0],
      });
    })
    .catch((error) => console.log(error));
};

export const editBodyPartSymptomAction = (data, id) => (dispatch) => {
  axiosInstance
  .put(editBodyPartSymptom+"/"+id, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_BODY_PART_SYMPTOM,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveBodyPartSymptomAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addBodyPartSymptom, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_BODY_PART_SYMPTOM,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteBodyPartSymptomAction = (id) => (dispatch) => {
  axiosInstance
  .delete(deleteBodyPartSymptom+"/"+id, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_BODY_PART_SYMPTOM,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveBodyPartSymptomDiseaseAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addBodyPartSymptomDisease, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_BODY_PART_SYMPTOM_DISEASE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

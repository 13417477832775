import { GET_ALL_THERAPY_INGREDIENT, EDIT_THERAPY_INGREDIENT, SAVE_THERAPY_INGREDIENT, DELETE_THERAPY_INGREDIENT } from "../actionType";
import { axiosInstance, getAllTherapyIngrediants, addTherapyIngrediant, editTherapyIngrediant, deleteTherapyIngrediant } from "../../services/Apis";

export const getAllIvTherapyIngredientAction = (ivTherapyId) => (dispatch) => {
  axiosInstance
    .get(getAllTherapyIngrediants+"/"+ivTherapyId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchDoctor[0]);
      dispatch({
        type: GET_ALL_THERAPY_INGREDIENT,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editIvTherapyIngredientAction = (data, ingredientId, ivTherapyId) => (dispatch) => {
  axiosInstance
  .put(editTherapyIngrediant+"/"+ingredientId, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_THERAPY_INGREDIENT,
      payload: response.data,
    });
  })
  .then(dispatch(getAllIvTherapyIngredientAction(ivTherapyId)))
  .catch((error) => console.log(error));
};

export const saveIvTherapyIngredientAction = (formData, ivTherapyId) => (dispatch) => {
  axiosInstance
  .post(addTherapyIngrediant, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_THERAPY_INGREDIENT,
      payload: response.data,
    });
  })
  .then(dispatch(getAllIvTherapyIngredientAction(ivTherapyId)))
  .catch((error) => console.log(error));
};

export const deleteIvTherapyIngredientAction = (ingredientId) => (dispatch) => {
  axiosInstance
  .delete(deleteTherapyIngrediant+"/"+ingredientId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_THERAPY_INGREDIENT,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import { s3Url } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllVideosAction, deleteVideoAction } from '../../../store/CityTreatmentFaqManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.cityTreatmentFaq);
  const dispatch = useDispatch();

  const [videos, setVideos] = useState([]);
  const [allVideos, setAllVideos] = useState([]);

  useEffect(() => {
    dispatch(getAllVideosAction(props.treatmentId, props.locationId));
  }, []);

  useEffect(() => {
    //Lists
    if (state.faqs !== undefined) {
      setVideos(state.faqs);
    }
  }, [state]);

  const handleDeleteVideo = (index) => {
    //console.log("ddddd",videos[index].video_id);
    dispatch(deleteVideoAction(videos[index].faq_id, props.treatmentId, props.locationId));
    //dispatch(getAllVideosAction(props.treatmentId, props.locationId));
  }

  const handleEditVideo = (video) => {
    //console.log(technique);
    props.setFaqId(video.faq_id)
    props.setAnswer(video.answer);
    props.setQuestion(video.question);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    const videos1 = videos.map((video, index) => (
      {
        faqQuestion: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {video.question}
        </MDTypography>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditVideo(video)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteVideo(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllVideos(videos1);
  }, [videos]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Question", accessor: "faqQuestion", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allVideos
  };
}

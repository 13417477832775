import { GET_ALL_CONDITIONS, EDIT_CONDITION, SAVE_CONDITION, DELETE_CONDITION } from "../actionType";

const initialState = {
  conditions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONDITIONS:
      return {
        ...state,
        conditions: action.payload,
      };

    case EDIT_CONDITION:
      return {
        ...state,
      };

    case SAVE_CONDITION:
      return {
        ...state,
      };

    case DELETE_CONDITION:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_SINGLE_DISEASE, GET_ALL_DISEASES, EDIT_DISEASE, SAVE_DISEASE, DELETE_DISEASE } from "../actionType";
import { axiosInstance, getSinlgeDisease, getAllDiseases, addDisease, editDisease, deleteDisease } from "../../services/Apis";

export const getSingleDiseaseAction = (diseaseId) => (dispatch) => {
  axiosInstance
  .get(getSinlgeDisease, {
    headers: {
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    //console.log(response.data.fetchClinic);
    dispatch({
      type: GET_SINGLE_DISEASE,
      payload: response.data.data,
    });
  })
  .catch((error) => console.log(error));
};

export const getAllDiseasesAction = () => (dispatch) => {
  axiosInstance
  .get(getAllDiseases, {
    headers: {
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    //console.log(response.data.fetchClinic);
    dispatch({
      type: GET_ALL_DISEASES,
      payload: response.data.data,
    });
  })
  .catch((error) => console.log(error));
};

export const editDiseaseAction = (formData, diseaseId) => (dispatch) => {
  axiosInstance
  .put(editDisease+"/"+diseaseId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_DISEASE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveDiseaseAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addDisease, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_DISEASE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteDiseaseAction = (diseaseId) => (dispatch) => {
  axiosInstance
  .delete(deleteDisease+"/"+diseaseId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_DISEASE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

import { GET_ALL_CITY_CENTERS, EDIT_CITY_CENTER, SAVE_CITY_CENTER, DELETE_CITY_CENTER } from "../actionType";

const initialState = {
  cityCenters: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CITY_CENTERS:
      return {
        ...state,
        cityCenters: action.payload,
      };

    case EDIT_CITY_CENTER:
      return {
        ...state,
      };

    case SAVE_CITY_CENTER:
      return {
        ...state,
      };

    case DELETE_CITY_CENTER:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import React, { useState, useEffect } from 'react';

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, TextField } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Autocomplete from '@mui/material/Autocomplete';
// Data
import clinicTableData from "layouts/clinic/data/clinicTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getAllClinicsAction, saveClinicAction, editClinicAction } from '../../store/ClinicManagement/action';
import { getAllActiveLocationsAction } from '../../store/LocationManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let locations = [];

function Clinics() {
  const locationState = useSelector(state=> state.location); 
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  //Form Data
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState([]);
  const [zipcode, setZipcode] = useState([]);
  const [clinicId, setClinicId] = useState("");

  const [location, setLocation] = useState({});
  const [locationId, setLocationId] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    setAddress("");
    setCity("");
    setState("");
    setZipcode("");
    setClinicId("");
    setShowActive(false);
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getAllActiveLocationsAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (locationState.activeLocations !== undefined) {
      locations = locationState.activeLocations;
      //setTreatmentOptions(treatmentState.treatments);
    }
  }, [locationState]);

  const { columns, rows } = clinicTableData({handleOpen, setName, setAddress, setCity, setState, setZipcode, setClinicId, setLocation, setShowActive });

  const uploadClinic = (event) => {
    event.preventDefault();   
    if(clinicId)
    {
      const formData = {
        "name" : name,
        "address" : address,
        "city" : city,
        "state" : state,
        "zipcode" : zipcode,
        "location_id" : locationId
      }
      dispatch(editClinicAction(formData, clinicId));  
      setClinicId("");
      dispatch(getAllClinicsAction()); 
      handleClose();    
    }else{
      const formData = {
        "name" : name,
        "address" : address,
        "city" : city,
        "state" : state,
        "zipcode" : zipcode,
        "location_id" : locationId
      }
      dispatch(saveClinicAction(formData));
      setClinicId("");
      dispatch(getAllClinicsAction()); 
      handleClose();
    }    
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Clinic
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadClinic}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Name</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Address</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                        id="size-small-standard-multi"
                        size="small"
                        options={locations}
                        onChange={(event, value) => setLocationId(value.location_id)}
                        getOptionLabel={(option) => option.title}
                        defaultValue={location}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={"location"+option.location_id}>
                                {option.title}
                              </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Location"
                                variant="standard"
                                //placeholder="Keyword"
                            />
                        )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">City</InputLabel>
                      <Input id="description" aria-describedby="my-helper-text" value={city} onChange={(e) => setCity(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">State</InputLabel>
                      <Input id="description" aria-describedby="my-helper-text" value={state} onChange={(e) => setState(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Zip Code</InputLabel>
                      <Input id="description" aria-describedby="my-helper-text" value={zipcode} onChange={(e) => setZipcode(e.target.value)}  />
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Clinics
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Clinics;

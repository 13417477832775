import { GET_ALL_IV_THERAPY_TESTIMONIALS, EDIT_IV_THERAPY_TESTIMONIAL, SAVE_IV_THERAPY_TESTIMONIAL, DELETE_IV_THERAPY_TESTIMONIAL } from "../actionType";

const initialState = {
  testimonials: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_IV_THERAPY_TESTIMONIALS:
      return {
        ...state,
        testimonials: action.payload,
      };

    case EDIT_IV_THERAPY_TESTIMONIAL:
      return {
        ...state,
      };

    case SAVE_IV_THERAPY_TESTIMONIAL:
      return {
        ...state,
      };

    case DELETE_IV_THERAPY_TESTIMONIAL:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import React, { useState, useEffect } from 'react';

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import conditionTableData from "layouts/condition/data/conditionTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getAllBodyPartsAction } from '../../store/BodyPartManagement/action';
import { saveConditionAction, editConditionAction } from '../../store/ConditionManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Conditions() {
  const state = useSelector(state=> state.bodyPart);
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  //Form Data
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [gender, setGender] = useState(0);
  const [bodyparts, setBodyParts] = useState([]);
  const [allBodyparts, setAllBodyParts] = useState([]);
  const [bodyPartId, setBodyPartId] = useState("");
  const [conditionId, setConditionId] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    setDescription("");
    setGender("");
    setBodyPartId("");
    setConditionId("");
    setShowActive(false);
    setOpen(false);
  };

  const { columns, rows } = conditionTableData({handleOpen, setName, setDescription, setGender, setBodyPartId, setConditionId, setShowActive });

  useEffect(() => {
    dispatch(getAllBodyPartsAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (state.bodyparts !== undefined) {
      setBodyParts(state.bodyparts);
    }   
  }, [state]);

  useEffect(() => {
    console.log(bodyparts);
    const bodyparts1 = bodyparts.map((bodypart, index) => (
      <MenuItem key={index} value={bodypart._id}>{bodypart.name}</MenuItem>
    ));
    setAllBodyParts(bodyparts1);
  }, [bodyparts]);

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const uploadCondition = (event) => {
    event.preventDefault();   
    if(conditionId)
    {
      const formData = {
        "title" : name,
        "description" : description,
        "gender" : gender
      }
      dispatch(editConditionAction(formData, conditionId, bodyPartId));  
      setConditionId("");
      dispatch(getAllBodyPartsAction()); 
      handleClose();    
    }else{
      const formData = {
        "title" : name,
        "description" : description,
        "gender" : gender
      }
      dispatch(saveConditionAction(formData, bodyPartId));
      setConditionId("");
      dispatch(getAllBodyPartsAction()); 
      handleClose();
    }    
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Condition
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadCondition}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Body Part</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Body Part"
                        value={bodyPartId}
                        onChange={(e) => setBodyPartId(e.target.value)}
                      >
                        {allBodyparts}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Description</InputLabel>
                      <Input id="description" aria-describedby="my-helper-text" value={description} onChange={(e) => setDescription(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Gender</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Gender"
                        value={gender}
                        onChange={handleGenderChange}
                      >
                        <MenuItem value={1}>Male</MenuItem>
                        <MenuItem value={2}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Conditions
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Conditions;

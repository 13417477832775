import { GET_ALL_LOCATIONS, GET_SINGLE_LOCATION, GET_ALL_ACTIVE_LOCATIONS, EDIT_LOCATION, SAVE_LOCATION, DELETE_LOCATION } from "../actionType";
import { axiosInstance, getAllLocation, getAllActiveLocation, addLocation, editLocation, deleteLocation } from "../../services/Apis";

export const getAllLocationsAction = () => (dispatch) => {
  axiosInstance
    .get(getAllLocation, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      dispatch({
        type: GET_ALL_LOCATIONS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const getSingleLocationAction = (locationId) => (dispatch) => {
  axiosInstance
    .get(getAllLocation+"/"+locationId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      dispatch({
        type: GET_SINGLE_LOCATION,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const getAllActiveLocationsAction = () => (dispatch) => {
  axiosInstance
    .get(getAllActiveLocation, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      dispatch({
        type: GET_ALL_ACTIVE_LOCATIONS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editLocationAction = (formData, locationId) => (dispatch) => {
  axiosInstance
  .put(editLocation+"/"+locationId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_LOCATION,
      payload: response.data,
    });
  })
  .then(dispatch(getAllLocationsAction()))
  .catch((error) => console.log(error));
};

export const saveLocationAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addLocation, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_LOCATION,
      payload: response.data,
    });
  })
  .then(dispatch(getAllLocationsAction()))
  .catch((error) => console.log(error));
};

export const deleteLocationAction = (locationId) => (dispatch) => {
  axiosInstance
  .delete(deleteLocation+"/"+locationId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_LOCATION,
      payload: response.data,
    });
  })
  .then(dispatch(getAllLocationsAction()))
  .catch((error) => console.log(error));
};

import { GET_ALL_SYMPTOM, GET_SINGLE_SYMPTOM, EDIT_SYMPTOM, SAVE_SYMPTOM, DELETE_SYMPTOM  } from "../actionType";

const initialState = {
  symptoms: [],
  symptom: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SYMPTOM:
      return {
        ...state,
        symptoms: action.payload
      };

    case GET_SINGLE_SYMPTOM:
      return {
        ...state,
        symptom: action.payload
      };

    case EDIT_SYMPTOM:
      return {
        ...state,
      };

    case SAVE_SYMPTOM:
      return {
        ...state,
      };

    case DELETE_SYMPTOM:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

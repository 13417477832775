/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import { s3Url } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllLocationsAction, deleteLocationAction } from '../../../store/LocationManagement/action';
import { Link } from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const locationState = useSelector(state=> state.location);
  const dispatch = useDispatch();

  const [locations, setLocations] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    dispatch(getAllLocationsAction());       
  }, []);

  useEffect(() => {
    //Lists
    //console.log(locationState);
    if (locationState.locations !== undefined) {
      setLocations(locationState.locations);
    }
  }, [locationState]);  

  useEffect(() => {
    //console.log(locations);
    const allLocations1 = locations.map((location, index) => (
      {
        bannerImage: <Author image={s3Url + "/location/" + location.upload_image} name={location.name} />,
        optionTitle: location.title,
        treatment: <Link to={"/city-treatment/"+location.location_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Treatment</MDButton></Link>,
        center: <Link to={"/city-center/"+location.location_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Center</MDButton></Link>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditLocation(location)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteLocation(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllLocations(allLocations1);
  }, [locations]);

  const handleDeleteLocation = (index) => {
    dispatch(deleteLocationAction(locations[index].location_id));   
    dispatch(getAllLocationsAction());
  }

  const handleEditLocation = (location) => {
    //console.log(option);
    props.setName(location.title);
    props.setGoogleMap(location.google_map);
    props.setBusinessName(location.business_name);
    props.setAddress1(location.address_1);
    props.setAddress2(location.address_2);
    props.setCity(location.city);
    props.setState(location.state);
    props.setZipCode(location.zip);
    props.setPhone(location.phone);
    props.setLocationId(location.location_id);
    props.setShowName(location.upload_image);
    props.setShowActive(true);
    props.handleOpen();
  };


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Title", accessor: "optionTitle", width: "30%", align: "left" },
      { Header: "Banner Image", accessor: "bannerImage", align: "center" },
      { Header: "Treatment", accessor: "treatment", width: "15%", align: "left" },
      { Header: "Center", accessor: "center", width: "15%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allLocations
  };
}

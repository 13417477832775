/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup } from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import { getAllServicesAction, deleteServiceAction } from '../../../store/ServiceManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.service);
  const dispatch = useDispatch();

  const [services, setServices] = useState([]);
  const [allServices, setAllServices] = useState([]);

  useEffect(() => {
    dispatch(getAllServicesAction());       
  }, []);

  useEffect(() => {
    //Lists
    
    if (state.services !== undefined) {
      //console.log(state.services);
      setServices(state.services);
    }
  }, [state]);

  const handleDeleteService = (index) => {
    dispatch(deleteServiceAction(services[index].service_id));   
    dispatch(getAllServicesAction());        
  }

  const handleEditService = (service) => {
    //console.log(condition);
    props.setName(service.name);
    props.setServiceId(service.service_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    //console.log(services);
    const allServices1 = services.map((service, index) => (
      {
        serviceName: service.name,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditService(service)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteService(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllServices(allServices1);
  }, [services]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Name", accessor: "serviceName", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allServices
  };
}

import { GET_ALL_IV_THERAPY_FAQS, EDIT_IV_THERAPY_FAQ, SAVE_IV_THERAPY_FAQ, DELETE_IV_THERAPY_FAQ } from "../actionType";
import { axiosInstance, getAllIvTherapyFaqs, addIvTherapyFaq, editIvTherapyFaq, deleteIvTherapyFaq } from "../../services/Apis";

export const getAllFaqsAction = (treatmentId) => (dispatch) => {
  axiosInstance
    .get(getAllIvTherapyFaqs+"/"+treatmentId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchFaq);
      dispatch({
        type: GET_ALL_IV_THERAPY_FAQS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editFaqAction = (formData, faqId, ivTherapyId) => (dispatch) => {
  axiosInstance
  .put(editIvTherapyFaq+"/"+faqId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_IV_THERAPY_FAQ,
      payload: response.data,
    });
  }).then(dispatch(getAllFaqsAction(ivTherapyId)))
  .catch((error) => console.log(error));
};

export const saveFaqAction = (formData, ivTherapyId) => (dispatch) => {
  axiosInstance
  .post(addIvTherapyFaq, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_IV_THERAPY_FAQ,
      payload: response.data,
    });
  }).then(dispatch(getAllFaqsAction(ivTherapyId)))
  .catch((error) => console.log(error));
};

export const deleteFaqAction = (faqId) => (dispatch) => {
  axiosInstance
  .delete(deleteIvTherapyFaq+"/"+faqId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_IV_THERAPY_FAQ,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

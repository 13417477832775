import { GET_ALL_CAUSES, EDIT_CAUSE, SAVE_CAUSE, DELETE_CAUSE } from "../actionType";

const initialState = {
  causes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CAUSES:
      return {
        ...state,
        causes: action.payload,
      };

    case EDIT_CAUSE:
      return {
        ...state,
      };

    case SAVE_CAUSE:
      return {
        ...state,
      };

    case DELETE_CAUSE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_BANNERS, EDIT_BANNER, SAVE_BANNER, DELETE_BANNER } from "../actionType";
import { axiosInstance, getAllBanners, addBanner, deleteBanner, editBanner } from "../../services/Apis";

export const getAllBannersAction = () => (dispatch) => {
  axiosInstance
    .get(getAllBanners, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchBanner);
      dispatch({
        type: GET_ALL_BANNERS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editBannerAction = (bannerId, data) => (dispatch) => {
  axiosInstance
  .put(editBanner+"/"+bannerId, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_BANNER,
      payload: response.data,
    });
    if(response.data.status == true)
    {
      dispatch(getAllBannersAction());
    }
  })
  .catch((error) => console.log(error));
};

export const saveBannerAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addBanner, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_BANNER,
      payload: response.data,
    });
    if(response.data.status == true)
    {
      dispatch(getAllBannersAction());
    }
  })
  .catch((error) => console.log(error));
};

export const deleteBannerAction = (bannerId) => (dispatch) => {
  axiosInstance
  .delete(deleteBanner+"/"+bannerId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_BANNER,
      payload: response.data,
    });
    if(response.data.status == true)
    {
      dispatch(getAllBannersAction());
    }
  })
  .catch((error) => console.log(error));
};

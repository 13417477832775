/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import { accessKeyId, secretAccessKey, region } from '../../../config';
import S3 from "react-aws-s3";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup } from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import { getAllCategorysAction, deleteCategoryAction } from '../../../store/PostCategoryManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.category);
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {
    dispatch(getAllCategorysAction());       
  }, []);

  useEffect(() => {
    //Lists
    
    if (state.categorys !== undefined) {
      setCategories(state.categorys);

    }
  }, [state]);

  const handleDeleteKeyword = (index) => {
    dispatch(deleteCategoryAction(categories[index].category_id));   
    dispatch(getAllCategorysAction());        
  }

  const handleEditKeyword = (category) => {
    //console.log(condition);
    props.setName(category.category_name);
    props.setCategoryId(category.category_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {

    const allcategory1 = categories.map((category, index) => (
      {
        CategoryName: category.category_name,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditKeyword(category)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteKeyword(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllCategories(allcategory1);
  }, [categories]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Category Name", accessor: "CategoryName", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allCategories
  };
}

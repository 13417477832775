/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import { s3Url, accessKeyId, secretAccessKey, region, bucketName } from '../../../config';
import S3 from "react-aws-s3";
import { Link } from "react-router-dom";
// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

// @mui material components

import {useDispatch, useSelector} from 'react-redux';
import { getAllTreatmentsAction, deleteTreatmentAction } from '../../../store/TreatmentManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.treatment);
  const bodyPartState = useSelector(state=> state.bodyPart); 
  const dispatch = useDispatch();

  const [treatments, setTreatments] = useState([]);
  const [allTreatments, setAllTreatments] = useState([]);
  const [bodyPartName, setBodyPartName] = useState("");

  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const config = {
    bucketName : bucketName,
    dirName : "treatment",
    region : region,
    accessKeyId : accessKeyId,
    secretAccessKey : secretAccessKey
  };

  useEffect(() => {
    dispatch(getAllTreatmentsAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (state.treatments !== undefined) {
        setTreatments(state.treatments);
        console.log("assd", bodyPartState.bodyparts);
    }
  }, [state]);

  const handleEditTreatment = (treatment) => {
    console.log(treatment);
    props.setPopular(treatment.popular);
    props.setName(treatment.title);
    props.setMenuTitle(treatment.menu_title);
    props.setBannerText(treatment.banner_text);
    props.setShortDescription(treatment.short_description);
    props.setShortDescription1(treatment.short_description);
    props.setCauseDescription(treatment.cause);
    props.setCauseDescription1(treatment.cause);
    props.setDescription(treatment.description);
    props.setDescription1(treatment.description);
    props.setCostDescription(treatment.cost_description);
    props.setCostDescription1(treatment.cost_description);
    props.setWhoCanGoDescription(treatment.who_can_go);
    props.setWhoCanGoDescription1(treatment.who_can_go);
    props.setRecoveryDescription(treatment.recovery_after_surgery);
    props.setRecoveryDescription1(treatment.recovery_after_surgery);
    props.setMetaTitle(treatment.meta_title);
    props.setMetaDescription(treatment.meta_description);
    props.setDoctorMetaTitle(treatment.doctor_meta_title);
    props.setDoctorMetaDescription(treatment.doctor_meta_description);
    props.setBeforeAfterMetaTitle(treatment.before_after_meta_title);
    props.setBeforeAfterMetaDescription(treatment.before_after_meta_description);
    props.setSymptoms(treatment.symptoms);
    props.setSymptoms1(treatment.symptoms);
    props.setCareBefore(treatment.care_before);
    props.setCareBefore1(treatment.care_before);
    props.setRiskComplication(treatment.risk_complication);
    props.setRiskComplication1(treatment.risk_complication);
    props.setCredentialMandatoryDoctor(treatment.credential_mandatory_doctor);
    props.setCredentialMandatoryDoctor1(treatment.credential_mandatory_doctor);
    props.setShowCredentialImageName(treatment.credential_image);
    props.setShowBrochureImageName(treatment.brochure);
    props.setShowMobileImageName(treatment.mobile_banner);
    props.setShowName(treatment.image);
    props.setTreatmentKeywords(treatment.keywords);
    props.setTreatmentMedicines(treatment.medicines);
    props.setBodyPartId(treatment.body_part_id);
    props.setTreatmentId(treatment.treatment_id);
    props.setCredentialAltText(treatment.credential_alt_text);
    props.setBannerAltText(treatment.banner_alt_text);
    props.setVideoMetaDescription(treatment.video_meta_description);
    props.setVideoMetaTitle(treatment.video_meta_title);
    props.setMaxPrice(treatment.max_price);
    props.setMinPrice(treatment.min_price);
    props.setShowActive(true);
    props.handleOpen();
  };

  const handleDeleteTreatment = (index) => {
    const image = treatments[index].image;
    const ReactS3Client = new S3(config);
    ReactS3Client
    .deleteFile(image)
    .then((response) => {})
    .catch(err => console.error(err))
    dispatch(deleteTreatmentAction(treatments[index].treatment_id));   
    dispatch(getAllTreatmentsAction());    
  }

  const fileInput = useRef();

  const handleFileInput = (e) => {
    // handle validations
    //alert(1);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  }

  useEffect(() => {
    
    const alltreatments1 = treatments.map((treatment, index) => {

      let bodypart = bodyPartState?.bodyparts.map((elem)=> {
        if(elem.body_part_id == treatment.body_part_id){
          return elem.name
        }
      });
      
      return({
        treatmentImage: <Author image={s3Url+"/treatment/"+treatment.image} name={treatment.title} />,
        treatmentTitle: treatment.title,
        // menuTitle: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        // {treatment.menu_title}
        // </MDTypography>,
        bodypartName: bodypart,
        technique: <Link to={"/technique/"+treatment.treatment_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Technique</MDButton></Link>,
        url: <Link to={"/url/"+treatment.treatment_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">URL</MDButton></Link>,
        faq: <Link to={"/faq/"+treatment.treatment_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Faq</MDButton></Link>,
        testimonial: <Link to={"/testimonial/"+treatment.treatment_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Testimonial</MDButton></Link>,
        video: <Link to={"/video/"+treatment.treatment_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Video</MDButton></Link>,
        // treatmentDescription: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        // {treatment.description}
        // </MDTypography>,
        // status: (
        //   <MDBox ml={-1}>
        //     <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
        //   </MDBox>
        // ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} color="success" variant="gradient" size="sm" onClick={() => handleEditTreatment(treatment)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton onClick={() => handleDeleteTreatment(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    )});
    setAllTreatments(alltreatments1);
  }, [treatments]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Banner Image", accessor: "treatmentImage", width: "25%", align: "left" },
      { Header: "Title", accessor: "treatmentTitle", width: "20%", align: "left" },
      // { Header: "Menu Title", accessor: "menuTitle", width: "20%", align: "left" },
      { Header: "Body Part", accessor: "bodypartName", width: "25%", align: "left" },
      { Header: "Technique", accessor: "technique", width: "25%", align: "left" },
      { Header: "URL", accessor: "url", width: "25%", align: "left" },
      { Header: "Faq", accessor: "faq", width: "25%", align: "left" },
      { Header: "Testimonial", accessor: "testimonial", width: "25%", align: "left" },
      { Header: "Video", accessor: "video", width: "25%", align: "left" },
      // { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allTreatments
  };
}

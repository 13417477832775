import { GET_ALL_TESTIMONIALS, EDIT_TESTIMONIAL, SAVE_TESTIMONIAL, DELETE_TESTIMONIAL } from "../actionType";
import { axiosInstance, getAllTestimonials, addTestimonial, editTestimonial, deleteTestimonial } from "../../services/Apis";

export const getAllTestimonialsAction = (treatmentId) => (dispatch) => {
  axiosInstance
    .get(getAllTestimonials+"/"+treatmentId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchTestimonial);
      dispatch({
        type: GET_ALL_TESTIMONIALS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editTestimonialAction = (formData, testimonialId) => (dispatch) => {
  axiosInstance
  .put(editTestimonial+"/"+testimonialId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_TESTIMONIAL,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveTestimonialAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addTestimonial, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_TESTIMONIAL,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteTestimonialAction = (testimonialId) => (dispatch) => {
  axiosInstance
  .delete(deleteTestimonial+"/"+testimonialId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_TESTIMONIAL,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

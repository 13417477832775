import { GET_ALL_KEYWORDS, EDIT_KEYWORD, SAVE_KEYWORD, DELETE_KEYWORD, LIKE_KEYWORDS } from "../actionType";

const initialState = {
  keywords: [],
  //likes: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_KEYWORDS:
      return {
        ...state,
        keywords: action.payload,
      };

    // case LIKE_KEYWORDS:
    //   return {
    //     ...state,
    //     likes: action.payload,
    //   };

    case EDIT_KEYWORD:
      return {
        ...state,
      };

    case SAVE_KEYWORD:
      return {
        ...state,
      };

    case DELETE_KEYWORD:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

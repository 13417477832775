import { GET_ALL_SPECIALIZATIONS, EDIT_SPECIALIZATION, SAVE_SPECIALIZATION, DELETE_SPECIALIZATION } from "../actionType";
import { axiosInstance, getAllSpecializations, addSpecialization, editSpecialization, deleteSpecialization } from "../../services/Apis";

export const getAllSpecializationsAction = () => (dispatch) => {
  axiosInstance
    .get(getAllSpecializations, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchSpecialization);
      dispatch({
        type: GET_ALL_SPECIALIZATIONS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editSpecializationAction = (formData, SpecializationId) => (dispatch) => {
  axiosInstance
  .put(editSpecialization+"/"+SpecializationId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_SPECIALIZATION,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveSpecializationAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addSpecialization, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_SPECIALIZATION,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteSpecializationAction = (specializationId) => (dispatch) => {
  axiosInstance
  .delete(deleteSpecialization+"/"+specializationId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_SPECIALIZATION,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

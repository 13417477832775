import { GET_ALL_AREAS, EDIT_AREA, SAVE_AREA, DELETE_AREA } from "../actionType";

const initialState = {
  areas: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_AREAS:
      return {
        ...state,
        areas: action.payload,
      };

    case EDIT_AREA:
      return {
        ...state,
      };

    case SAVE_AREA:
      return {
        ...state,
      };

    case DELETE_AREA:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import React, { useState, useEffect, useRef } from 'react';
import S3 from "react-aws-s3";
import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../config';
import {  useParams } from "react-router-dom";
// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, Typography, Breadcrumbs, Link } from '@mui/material';
//import Breadcrumbs from "examples/Breadcrumbs";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import techniqueTableData from "layouts/technique/data/techniqueTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getSingleTreatmentAction } from '../../store/TreatmentManagement/action';
import { getAllTechniquesAction, saveTechniqueAction, editTechniqueAction } from '../../store/TechniqueManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Options() {
  let { treatmentId } = useParams();
  const state = useSelector(state=> state.treatment);
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  const [file, setFile] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [showName, setShowName] = React.useState("");
  //Form Data
  const [name, setName] = useState("");
  const [treatment, setTreatment] = useState({});
  const [route, setRoute] = useState([""]);
  const [allTreatments, setAlltreatments] = useState([]);
  //const [treatmentId, setTreatmentId] = useState("");
  const [techniqueId, setTechniqueId] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    //setTreatmentId("");
    setTechniqueId("");
    setShowActive(false);
    setOpen(false);
  };

  const { columns, rows } = techniqueTableData({treatmentId, handleOpen, setName, setShowName, setTechniqueId, setShowActive });

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g,"");  
    var random = ("" + Math.random()).substring(2, 8); 
    var random_number = timestamp+random;  
    return random_number;
  };

  const fileInput = useRef();

  const handleFileInput = (e) => {
    // handle validations
    //alert(1);
    setFile(e.target.files[0]);
    setFileName(getRandomFileName()+e.target.files[0].name);
  };

  useEffect(() => {
    dispatch(getSingleTreatmentAction(treatmentId));       
  }, [treatmentId]);

  useEffect(() => {
    //Lists
    if (state.treatment !== undefined) {
      setTreatment(state.treatment);
      //console.log(state.treatment);
    }   
  }, [state]);

  // useEffect(() => {
    
  //   const treatments1 = treatments.map((treatment, index) => (
  //     <MenuItem key={index} value={treatment.treatment_id}>{treatment.title}</MenuItem>
  //   ));
  //   //console.log(treatments1);
  //   setAlltreatments(treatments1);
  // }, [treatments]);

  const uploadCause = (event) => {
    event.preventDefault();
    const config = {
      bucketName : bucketName,
      dirName : "technique",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (techniqueId)
    {
      if(fileName != "")
      {
        ReactS3Client
        .deleteFile(s3Url+"/technique/"+showName)
        .then((response) => {
          ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            //setS3FileUrl(data.location);
            const formData = {
              "name" : name,
              "upload_image" : fileName,
              "treatment_id" : treatmentId
            }
            dispatch(editTechniqueAction(formData, techniqueId));
            setTechniqueId("");
            dispatch(getAllTechniquesAction()); 
            handleClose();
          })
        })
        .catch(err => console.error(err))
      }else{
        const formData = {
          "name" : name,
          "upload_image" : showName,
          "treatment_id" : treatmentId
        }
        dispatch(editTechniqueAction(formData, techniqueId));
        setTechniqueId("");
        dispatch(getAllTechniquesAction()); 
        handleClose();
      }
    }else{
      ReactS3Client.uploadFile(file, fileName)
      .then(data => {
        //console.log(data.location);
        //setS3FileUrl(data.location);
        const formData = {
          "name" : name,
          "upload_image" : fileName,
          "treatment_id" : treatmentId
        }
        dispatch(saveTechniqueAction(formData, treatmentId));
        dispatch(getAllTechniquesAction()); 
        handleClose();
      })
    }
  }

  return (
    <DashboardLayout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/backend/dashboard">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/backend/treatments"
        >
          {treatment.title}
        </Link>
        <Typography color="text.primary">Technique</Typography>
      </Breadcrumbs>
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Technique
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadCause}>
                <FormGroup>
                  {/* <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Treatment</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Treatment"
                        value={treatmentId}
                        onChange={(e) => setTreatmentId(e.target.value)}
                      >
                        {allTreatments}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Image</InputLabel>
                      <Box component="img" src={s3Url+"/technique/"+showName}></Box>
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Techniques
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Options;

import { GET_ALL_CLINICS, EDIT_CLINIC, SAVE_CLINIC, DELETE_CLINIC } from "../actionType";

const initialState = {
  clinics: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CLINICS:
      return {
        ...state,
        clinics: action.payload,
      };

    case EDIT_CLINIC:
      return {
        ...state,
      };

    case SAVE_CLINIC:
      return {
        ...state,
      };

    case DELETE_CLINIC:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import React, { useState, useEffect, useRef } from 'react';

import S3 from "react-aws-s3";

import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../config';

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import bodyPartTableData from "layouts/bodyPart/data/bodyPartTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getAllMajorCategorysAction } from '../../store/MajorCategoryManagement/action';
import { getAllBodyPartsAction, editBodyPartAction, saveBodyPartAction } from '../../store/BodyPartManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let mainimageName1 = "";
let mainHoverImageName1 = "";

function BodyParts() {
  const state = useSelector(state=> state.majorCategory);
  const dispatch = useDispatch();

  const [image, setImage] = React.useState([]);
  const [imageName, setImageName] = React.useState("");
  const [mainimageName, setMainImageName] = React.useState("");

  const [hoverImage, setHoverImage] = React.useState([]);
  const [hoverImageName, setHoverImageName] = React.useState("");
  const [mainHoverImageName, setMainHoverImageName] = React.useState("");

  const [brownIcon, setBrownIcon] = React.useState([]);
  const [brownIconName, setBrownIconName] = React.useState("");

  const [whiteIcon, setWhiteIcon] = React.useState([]);
  const [whiteIconName, setWhiteIconName] = React.useState("");

  const [showImageName, setShowImageName] = React.useState("");
  const [showHoverImageName, setShowHoverImageName] = React.useState("");
  const [showBrownIconName, setShowBrownIconName] = React.useState("");
  const [showWhiteIconName, setShowWhiteIconName] = React.useState("");

  const [s3fileUrl, setS3FileUrl] = React.useState("");

  //Form Data
  const [name, setName] = useState("");
  const [popular, setPopular] = useState(false);
  const [isAgeRestricted, setIsAgeRestricted] = useState(false);
  const [description, setDescription] = useState("");
  const [rank, setRank] = useState("");
  const [majorCategorys, setMajorCategorys] = useState([]);
  const [allMajorCategorys, setAllMajorCategorys] = useState([]);
  const [majorCategoryId, setMajorCategoryId] = useState("");
  const [bodyPartId, setBodyPartId] = useState("");
  const [showActive, setShowActive] = React.useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  //const handleClose = () => setOpen(false);

  const handleClose = () => {
    setName("");
    setPopular(false);
    setDescription("");
    setRank("");
    setShowImageName("");
    setShowHoverImageName("");
    setShowBrownIconName("");
    setShowWhiteIconName("");
    setMajorCategoryId("");
    setBodyPartId("");
    setShowActive(false);
    setOpen(false);
  };

  const { columns, rows } = bodyPartTableData({handleOpen, setName, setPopular, setIsAgeRestricted, setDescription, setRank, setShowImageName, setShowHoverImageName, setShowBrownIconName, setShowWhiteIconName, setMajorCategoryId, setBodyPartId, setShowActive });

  
  const fileInput = useRef();

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g,"");  
    var random = ("" + Math.random()).substring(2, 8); 
    var random_number = timestamp+random;  
    return random_number;
  }

  const handleImageInput = (e) => {
    // handle validations
    //alert(1);
    setImage(e.target.files[0]);
    setImageName(getRandomFileName()+e.target.files[0].name);
  }

  const handleHoverImageInput = (e) => {
    // handle validations
    //alert(1);
    setHoverImage(e.target.files[0]);
    setHoverImageName(getRandomFileName()+e.target.files[0].name);
  }

  const handleBrownIcon = (e) => {
    // handle validations
    //alert(1);
    setBrownIcon(e.target.files[0]);
    setBrownIconName(getRandomFileName()+e.target.files[0].name);
  }

  const handleWhiteIcon = (e) => {
    // handle validations
    //alert(1);
    setWhiteIcon(e.target.files[0]);
    setWhiteIconName(getRandomFileName()+e.target.files[0].name);
  }

  useEffect(() => {
    dispatch(getAllMajorCategorysAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (state.categories !== undefined) {
      setMajorCategorys(state.categories);
    }   
  }, [state]);

  useEffect(() => {    
    const majorCategorys1 = majorCategorys.map((category, index) => (
      <MenuItem key={index} value={category.major_category_id}>{category.name}</MenuItem>
    ));
    //console.log(majorCategorys1);
    setAllMajorCategorys(majorCategorys1);
  }, [majorCategorys]);

  const handlePopularChange = (event) => {
    setPopular(event.target.value);
  };
  
  const handleAgeChange = (event) => {
    setIsAgeRestricted(event.target.value);
  };
  const uploadBodyPart = (event) => {
    event.preventDefault();
    const config = {
      bucketName : bucketName,
      dirName : "bodyPart",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (bodyPartId)
    {
      let mainimageName1 = "";
      let mainHoverImageName = "";
      let mainBrownIconName1 = "";
      let mainWhiteIconName1 = "";
      if(imageName != "")
      {
        if(showImageName == ""){
          ReactS3Client.uploadFile(image, imageName)
          .then(data1 => {
            mainimageName1 = imageName;
            //setMainImageName(imageName);
          })
          mainimageName1 = imageName;
        }else{
          ReactS3Client
          .deleteFile(showImageName)
          .then((response) => {
            ReactS3Client.uploadFile(image, imageName)
            .then(data1 => {              
              //setMainImageName(imageName);
            })
          })
          .catch(err => console.error(err));
          mainimageName1 = imageName;
        }        
      }else{
        console.log(showImageName);
        mainimageName1 = showImageName;
        //setMainImageName(showImageName);
      }

      if(hoverImageName != "")
      {
        if(showHoverImageName == "")
        {
          ReactS3Client.uploadFile(hoverImage, hoverImageName)
          .then(data1 => {
            //mainHoverImageName1 = hoverImageName;
            //setMainHoverImageName(imageName);
          })
          mainHoverImageName1 = hoverImageName;
        }else{
          ReactS3Client
          .deleteFile(showHoverImageName)
          .then((response) => {
            ReactS3Client.uploadFile(hoverImage, hoverImageName)
            .then(data1 => {
              
              //setMainHoverImageName(imageName);
            })
          })
          .catch(err => console.error(err));
          mainHoverImageName1 = hoverImageName;
        }
      }else{
        mainHoverImageName1 = showHoverImageName;
        //setMainHoverImageName(showHoverImageName);
      }

      if(brownIconName != "")
      {
        if(showBrownIconName == "")
        {
          ReactS3Client.uploadFile(brownIcon, brownIconName)
          .then(data1 => {
            //mainHoverImageName1 = hoverImageName;
            //setMainHoverImageName(imageName);
          })
          mainBrownIconName1 = brownIconName;
        }else{
          ReactS3Client
          .deleteFile(showBrownIconName)
          .then((response) => {
            ReactS3Client.uploadFile(brownIcon, brownIconName)
            .then(data1 => {
              
              //setMainHoverImageName(imageName);
            })
          })
          .catch(err => console.error(err));
          mainBrownIconName1 = brownIconName;
        }
      }else{
        mainBrownIconName1 = showBrownIconName;
      }

      if(whiteIconName != "")
      {
        if(showWhiteIconName == "")
        {
          ReactS3Client.uploadFile(whiteIcon, whiteIconName)
          .then(data1 => {
            //mainHoverImageName1 = hoverImageName;
            //setMainHoverImageName(imageName);
          })
          mainWhiteIconName1 = whiteIconName;
        }else{
          ReactS3Client
          .deleteFile(showWhiteIconName)
          .then((response) => {
            ReactS3Client.uploadFile(whiteIcon, whiteIconName)
            .then(data1 => {
              
              //setMainHoverImageName(imageName);
            })
          })
          .catch(err => console.error(err));
          mainWhiteIconName1 = whiteIconName;
        }
      }else{
        mainWhiteIconName1 = showWhiteIconName;
      }

      const formData = {
        "major_category_id" : majorCategoryId,
        "popular" : popular,
        "age_restriction":isAgeRestricted,
        "name" : name,
        "description" : description,
        "rank" : rank,
        "active_image" : mainimageName1,
        "hover_image" : mainHoverImageName1,
        "brown_icon" : mainBrownIconName1,
        "white_icon" : mainWhiteIconName1
      };
      
      dispatch(editBodyPartAction(formData, bodyPartId));
      setBodyPartId("");
      handleClose();
    }else{
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(image, imageName)
      .then(data => {
        ReactS3Client.uploadFile(hoverImage, hoverImageName)
        .then(data1 => {
          ReactS3Client.uploadFile(brownIcon, brownIconName)
          .then(data1 => {
            ReactS3Client.uploadFile(whiteIcon, whiteIconName)
            .then(data1 => {
              const formData = {
                "major_category_id" : majorCategoryId,
                "popular" : popular,
                "age_restriction":isAgeRestricted,
                "name" : name,
                "description" : description,
                "rank" : rank,
                "active_image" : imageName,
                "hover_image" : hoverImageName,
                "brown_icon" : brownIconName,
                "white_icon" : whiteIconName
              }
              dispatch(saveBodyPartAction(formData));
              handleClose();
            })
          })
        })
      })
    }
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Body Part
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadBodyPart}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Major Category</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Major Category"
                        value={majorCategoryId}
                        onChange={(e) => setMajorCategoryId(e.target.value)}
                      >
                        {allMajorCategorys}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label1">Popular</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Popular"
                        value={popular}
                        onChange={handlePopularChange}
                      >
                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label1">Age Restriction</InputLabel>
                      <Select
                        labelId="age-restriction"
                        id="demo-simple-select"
                        label="Age Restriction"
                        value={isAgeRestricted}
                        onChange={handleAgeChange}
                      >
                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Description</InputLabel>
                      <Input id="description" aria-describedby="my-helper-text" value={description} onChange={(e) => setDescription(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Rank</InputLabel>
                      <Input id="rank" aria-describedby="my-helper-text" value={rank} onChange={(e) => setRank(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleImageInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Image</InputLabel>
                      <Box component="img" src={s3Url+"/bodyPart/"+showImageName} style={{ "width":100 }}></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Hover Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleHoverImageInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Hover Image</InputLabel>
                      <Box component="img" src={s3Url+"/bodyPart/"+showHoverImageName} style={{ "width":100 }}></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Brown Icon</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleBrownIcon} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Brown Icon</InputLabel>
                      <Box component="img" src={s3Url+"/bodyPart/"+showBrownIconName} style={{ "width":100 }}></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload White Icon</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleWhiteIcon} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing White Icon</InputLabel>
                      <Box component="img" src={s3Url+"/bodyPart/"+showWhiteIconName} style={{ "width":100 }}></Box>
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Body Parts
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BodyParts;

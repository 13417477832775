import { GET_ALL_SERVICES, EDIT_SERVICE, SAVE_SERVICE, DELETE_SERVICE } from "../actionType";
import { axiosInstance, getAllServices, addService, editService, deleteService } from "../../services/Apis";

export const getAllServicesAction = () => (dispatch) => {
  axiosInstance
    .get(getAllServices, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchSERVICE);
      dispatch({
        type: GET_ALL_SERVICES,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editServiceAction = (formData, serviceId) => (dispatch) => {
  axiosInstance
  .put(editService+"/"+serviceId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_SERVICE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveServiceAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addService, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_SERVICE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteServiceAction = (serviceId) => (dispatch) => {
  axiosInstance
  .delete(deleteService+"/"+serviceId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_SERVICE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

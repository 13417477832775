import { GET_ALL_OPTIONS, EDIT_OPTION, SAVE_OPTION, DELETE_OPTION } from "../actionType";

const initialState = {
  options: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_OPTIONS:
      return {
        ...state,
        options: action.payload,
      };

    case EDIT_OPTION:
      return {
        ...state,
      };

    case SAVE_OPTION:
      return {
        ...state,
      };

    case DELETE_OPTION:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import { GET_ALL_NEWSLETTER, EDIT_NEWSLETTER, SAVE_NEWSLETTER, DELETE_NEWSLETTER } from "../actionType";
import { axiosInstance, getAllNewsletter, addNewsletter, editNewsletter, deleteNewsletter } from "../../services/Apis";

export const getAllNewsletterAction = () => (dispatch) => {
  axiosInstance
    .get(getAllNewsletter, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchClinic);
      dispatch({
        type: GET_ALL_NEWSLETTER,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editNewsletterAction = (formData, NewsletterId) => (dispatch) => {
  axiosInstance
  .put(editNewsletter+"/"+NewsletterId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_NEWSLETTER,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveNewsletterAction = (formData) => (dispatch) => {
  console.log("save", formData);
  axiosInstance
  .post(addNewsletter, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_NEWSLETTER,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteNewsletterAction = (NewsletterId) => (dispatch) => {
  axiosInstance
  .delete(deleteNewsletter+"/"+NewsletterId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_NEWSLETTER,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};
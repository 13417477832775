import { GET_ALL_DISEASE_TREATMENTS, EDIT_DISEASE_TREATMENT, SAVE_DISEASE_TREATMENT, DELETE_DISEASE_TREATMENT } from "../actionType";

const initialState = {
  diseaseTreatments: [],
  treatment: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DISEASE_TREATMENTS:
      return {
        ...state,
        diseaseTreatments: action.payload,
        treatment: action.payload.treatment
      };

    case EDIT_DISEASE_TREATMENT:
      return {
        ...state,
      };

    case SAVE_DISEASE_TREATMENT:
      return {
        ...state,
      };

    case DELETE_DISEASE_TREATMENT:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

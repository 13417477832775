/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';
import S3 from "react-aws-s3";
import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllDoctorClinicAction, deleteDoctorClinicAction } from '../../../store/DoctorClinicManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.doctorClinic);
  const dispatch = useDispatch();

  const [doctorClinics, setDoctorClinics] = useState([]);
  const [allDoctorClinics, setAllDoctorClinics] = useState([]);

  useEffect(() => {
    dispatch(getAllDoctorClinicAction(props.doctorId));       
  }, []);

  useEffect(() => {
    //Lists
    if (state.doctorClinics !== undefined) {
      setDoctorClinics(state.doctorClinics);
    }
  }, [state]);

  const handleDeleteDoctorClinic = (index) => {
    dispatch(deleteDoctorClinicAction(doctorClinics[index].id));   
    dispatch(getAllDoctorClinicAction(props.doctorId));
  }

  const handleEditDoctorClinic = (clinic) => {
    //console.log(cause);
    props.setConsultationFee(clinic.consultation_fee);
    props.setTiming(clinic.timing);
    props.setClinicId(clinic.clinic_id);
    props.setDoctorClinicId(clinic.id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    //console.log(doctorClinics);
    const allDoctorClinics1 = doctorClinics.map((doctorClinic, index) => (
      {
        doctorClinicTitle: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {doctorClinic.name}
        </MDTypography>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditDoctorClinic(doctorClinic)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteDoctorClinic(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllDoctorClinics(allDoctorClinics1);
  }, [doctorClinics]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Name", accessor: "doctorClinicTitle", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allDoctorClinics
  };
}

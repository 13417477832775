import { SET_AUTHENTICATION } from '../actionType';

const initialState = {
    isAunthenticated: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTHENTICATION:
            return {
                ...state,
                isAunthenticated: action.param,
            };

        default:
            return state;
    }
};

export default reducer;
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

import { region, accessKeyId, secretAccessKey, s3Url, S3, bucketName } from '../../../config';
import { Link } from "react-router-dom";
// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllTherapyAction, deleteTherapyAction } from '../../../store/IvTherapyManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.ivTherapy);
  const dispatch = useDispatch();

  const [doctors, setDoctors] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);


  useEffect(() => {
    dispatch(getAllTherapyAction());
  }, []);

  useEffect(() => {
    //Lists
    if (state.therapies !== undefined) {
      setDoctors(state.therapies);
    }    
    // axios.get("http://api.tamiraplatform.metricserp.net/api/v1/doctor/fetch-all-banner",{
    //   headers: {
    //     "x-auth-token": sessionStorage.getItem('userToken') //the token is a variable which holds the token
    //   }
    // })
    // .then(result => {
    //   console.log(result.data.fetchBanner);
    //   setDoctors(result.data.fetchBanner);
    // })
  }, [state]);

  const handleDeleteDoctor = (index) => {
    // const config = {
    //   bucketName : bucketName,
    //   dirName : "doctor",
    //   region : region,
    //   accessKeyId : accessKeyId,
    //   secretAccessKey : secretAccessKey
    // };
    // const ReactS3Client = new S3(config);
    // ReactS3Client
    // .deleteFile(s3Url+"/doctor/"+doctors[index].upload_image)
    // .then((response) => {
    //   dispatch(deleteDoctorAction(doctors[index].user_id));   
    //   dispatch(getAllDoctorsAction());
    // })
    // .catch(err => console.error(err))
    dispatch(deleteTherapyAction(doctors[index].ivtherapy_id));   
    dispatch(getAllTherapyAction());
  }

  const handleEditDoctor = (doctor) => {
    console.log(doctor)
    props.setTitle(doctor.title);
    props.setShortDesc(doctor.short_description);
    props.setIntroduction(doctor.introduction);
    props.setBenefits(doctor.benefits);
    props.setWhocantherapy(doctor.who_can_therapy);
    props.setShowTherapyImageName(doctor.who_can_therapy_image);
    props.setShowLogoImageName(doctor.ivtherapy_logo);
    props.setShowName(doctor.banner_image);    
    props.setDoctorId(doctor.ivtherapy_id);
    props.setAlt(doctor.banner_alt);
    props.setShowActive(true);
    props.setShowActive1(true);
    props.setShowActive2(true);
    props.handleOpen();
  };

  useEffect(() => {
    //console.log(doctors);
    const allDoctors1 = doctors.map((doctor, index) => (
      {
        bannerImage: <Author image={s3Url+"/ivtherapy-who-can-therapy/"+doctor.banner_image} name={doctor.name} />,
        title: doctor.title,
        ivtherapybenefit: <Link to={"/iv-therapy-benefit/"+doctor.ivtherapy_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Benefit</MDButton></Link>,
        ivtherapyingedrients: <Link to={"/iv-therapy-ingredient/"+doctor.ivtherapy_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Ingredients</MDButton></Link>,
        testimonial:<Link to={"/iv-therapy-testimonial/"+doctor.ivtherapy_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Testimonial</MDButton></Link>,
        faq:<Link to={"/iv-therapy-faq/"+doctor.ivtherapy_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Faq</MDButton></Link>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={()=>handleEditDoctor(doctor)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteDoctor(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllDoctors(allDoctors1);
    //console.log(allDoctors1);
  }, [doctors]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Banner Image", accessor: "bannerImage", width: "20%", align: "left" },
      { Header: "Title", accessor: "title", width: "20%", align: "left" },
      { Header: "Link", accessor: "ivtherapybenefit", align: "left" },
      { Header: "Link", accessor: "ivtherapyingedrients", align: "left" },
      { Header: "Link", accessor: "testimonial", align: "left" },
      { Header: "Link", accessor: "faq", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: allDoctors
  };
}

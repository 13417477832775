import { GET_ALL_DOCTOR_CLINICS, EDIT_DOCTOR_CLINIC, SAVE_DOCTOR_CLINIC, DELETE_DOCTOR_CLINIC  } from "../actionType";
import { axiosInstance, getAllDoctorClinics, addDoctorClinic, editDoctorClinic, deleteDoctorClinic } from "../../services/Apis";

export const getAllDoctorClinicAction = (doctorId) => (dispatch) => {
  axiosInstance
    .get(getAllDoctorClinics+"/"+doctorId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchCause);
      dispatch({
        type: GET_ALL_DOCTOR_CLINICS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editDoctorClinicAction = (formData, id) => (dispatch) => {
  axiosInstance
  .put(editDoctorClinic+"/"+id, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_DOCTOR_CLINIC,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveDoctorClinicAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addDoctorClinic, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_DOCTOR_CLINIC,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteDoctorClinicAction = (clinicId) => (dispatch) => {
  axiosInstance
  .delete(deleteDoctorClinic+"/"+clinicId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_DOCTOR_CLINIC,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

import S3 from "react-aws-s3";

import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllDiseasesAction, deleteDiseaseAction } from '../../../store/DiseaseManagement/action';
import { Link } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.disease);
  const dispatch = useDispatch();

  const [diseases, setDiseases] = useState([]);
  const [allDiseases, setAllDiseases] = useState([]);


  useEffect(() => {
    dispatch(getAllDiseasesAction(props.bodyPartId, props.symptomId));       
  }, []);

  useEffect(() => {
    //Lists
    if (state.diseases !== undefined) {
      setDiseases(state.diseases);
    }
  }, [state]);

  const handleDeleteDisease = (index) => {
    const config = {
      bucketName : bucketName,
      dirName : "disease",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    ReactS3Client
    .deleteFile(s3Url+"/disease/"+diseases[index].upload_image)
    .then((response) => {
      dispatch(deleteDiseaseAction(diseases[index].disease_id));   
      dispatch(getAllDiseasesAction(props.bodyPartId, props.symptomId));
    })
    .catch(err => console.error(err))
  }

  const handleEditDisease = (disease) => {
    props.setName(disease.name);
    props.setDescription(disease.description);
    props.setDescription1(disease.description);
    props.setShowName(disease.upload_image);
    props.setDiseaseTreatments(disease.treatments);
    props.setDiseaseMedicines(disease.medicines);
    props.setDiseaseId(disease.disease_id);
    props.setMetaTitle(disease.meta_title);
    props.setMetaDescription(disease.meta_description);
    props.setAltText(disease.disease_alt_text);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    
    const allDiseases1 = diseases.map((disease, index) => (
      {
        diseaseImage: <Author image={s3Url+"/disease/"+disease.upload_image} name={s3Url+"/disease/"+disease.image} />,
        diseaseName: disease.name,
        treatment: <Link to={"/disease-treatment/"+disease.disease_id}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Treatment</MDButton></Link>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={()=>handleEditDisease(disease)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteDisease(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllDiseases(allDiseases1);
  }, [diseases]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Disease Image", accessor: "diseaseImage", width: "30%", align: "left" },
      { Header: "Disease Name", accessor: "diseaseName", width: "30%", align: "left" },
      { Header: "Treatment", accessor: "treatment", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: allDiseases
  };
}

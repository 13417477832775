import { GET_ALL_TECHNIQUES, EDIT_TECHNIQUE, SAVE_TECHNIQUE, DELETE_TECHNIQUE } from "../actionType";
import { axiosInstance, getAllTechniques, addTechnique, editTechnique, deleteTechnique } from "../../services/Apis";

export const getAllTechniquesAction = (treatmentId) => (dispatch) => {
  axiosInstance
    .get(getAllTechniques+"/"+treatmentId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchTechnique);
      dispatch({
        type: GET_ALL_TECHNIQUES,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editTechniqueAction = (formData, techniqueId) => (dispatch) => {
  axiosInstance
  .put(editTechnique+"/"+techniqueId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_TECHNIQUE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveTechniqueAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addTechnique, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_TECHNIQUE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteTechniqueAction = (TechniqueId) => (dispatch) => {
  axiosInstance
  .delete(deleteTechnique+"/"+TechniqueId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_TECHNIQUE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

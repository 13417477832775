import React, { useState, useEffect, useRef } from 'react';
import S3 from "react-aws-s3";
import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../config';
import {  useParams } from "react-router-dom";

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, Typography, Breadcrumbs, Link } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import symptomTableData from "layouts/symptom/data/symptomTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getSingleBodyPartAction } from '../../store/BodyPartManagement/action';
import { getAllSymptomAction, saveSymptomAction, editSymptomAction } from '../../store/SymptomManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Symptom() {
  let { bodyPartId } = useParams();
  const state = useSelector(state=> state.bodyPart);
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  const [file, setFile] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [showName, setShowName] = React.useState("");
  //Form Data
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [gender, setGender] = useState(0);
  const [bodyPart, setBodyPart] = useState({});
  const [symptomId, setSymptomId] = useState("");
  const [altText, setAltText] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    setDescription("");
    setGender("");
    setSymptomId("");
    setShowActive(false);
    setOpen(false);
    setAltText("");
  };

  const { columns, rows } = symptomTableData({handleOpen, setName, setDescription, setGender, setShowName, bodyPartId, setSymptomId, setShowActive, setAltText });

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g,"");  
    var random = ("" + Math.random()).substring(2, 8); 
    var random_number = timestamp+random;  
    return random_number;
  };

  const fileInput = useRef();

  const handleFileInput = (e) => {
    // handle validations
    //alert(1);
    setFile(e.target.files[0]);
    setFileName(getRandomFileName()+e.target.files[0].name);
  };

  useEffect(() => {
    dispatch(getSingleBodyPartAction(bodyPartId));       
  }, [bodyPartId]);

  useEffect(() => {
    //Lists
    if (state.bodyPart !== undefined) {
      setBodyPart(state.bodyPart);
      console.log(state.bodyPart);
    }   
  }, [state]);

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const uploadSymptom = (event) => {
    event.preventDefault();
    const config = {
      bucketName : bucketName,
      dirName : "symptom",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (symptomId)
    {
      if(fileName != "")
      {
        ReactS3Client
        .deleteFile(s3Url+"/symptom/"+showName)
        .then((response) => {
          ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            //setS3FileUrl(data.location);
            const formData = {
              "name" : name,
              "gender" : gender,
              "upload_image" : fileName,
              "symptom_alt_text": altText
            }
            dispatch(editSymptomAction(formData, symptomId));
            setSymptomId("");
            dispatch(getAllSymptomAction(bodyPartId)); 
            handleClose();
          })
        })
        .catch(err => console.error(err))
      }else{
        const formData = {
          "name" : name,
          "gender" : gender,
          "symptom_alt_text": altText
        }
        dispatch(editSymptomAction(formData, symptomId));
        setSymptomId("");
        dispatch(getAllSymptomAction(bodyPartId)); 
        handleClose();
      }
    }else{
      if(fileName != "")
      {
        ReactS3Client.uploadFile(file, fileName)
        .then(data => {
          //console.log(data.location);
          //setS3FileUrl(data.location);
          const formData = {
            "name" : name,
            "body_part_id" : bodyPartId,
            "gender" : gender,
            "upload_image" : fileName,
            "symptom_alt_text": altText
          }
          dispatch(saveSymptomAction(formData));
          dispatch(getAllSymptomAction(bodyPartId)); 
          handleClose();
        })
      }else{
        const formData = {
          "name" : name,
          "gender" : gender,
          "symptom_alt_text": altText
        }
        dispatch(saveSymptomAction(formData));
        dispatch(getAllSymptomAction(bodyPartId)); 
        handleClose();
      }
    }
  }

  return (
    <DashboardLayout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/backend/dashboard">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/backend/body-parts"
        >
          {bodyPart.name}
        </Link>
        <Typography color="text.primary">Symptom</Typography>
      </Breadcrumbs>
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Symptom
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadSymptom}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="gender-label">Gender</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="demo-simple-select"
                        label="Gender"
                        value={gender}
                        onChange={handleGenderChange}
                      >
                        <MenuItem value={1}>Male</MenuItem>
                        <MenuItem value={2}>Female</MenuItem>
                        <MenuItem value={3}>Both</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Alt Text</InputLabel>
                      <Input id="alttext" value={altText} aria-describedby="my-helper-text" onChange={(e) => setAltText(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Image</InputLabel>
                      <Box component="img" src={s3Url+"/symptom/"+showName}></Box>
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Symptoms
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Symptom;

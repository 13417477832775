import { GET_ALL_KEYWORDS, EDIT_KEYWORD, SAVE_KEYWORD, DELETE_KEYWORD, LIKE_KEYWORDS } from "../actionType";
import { axiosInstance, getAllKeywords, likeKeywords, addKeyword, editKeyword, deleteKeyword } from "../../services/Apis";

export const getAllKeywordsAction = () => (dispatch) => {
  axiosInstance
    .get(getAllKeywords, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchClinic);
      dispatch({
        type: GET_ALL_KEYWORDS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

// export const likeKeywordsAction = () => (dispatch) => {
//   axiosInstance
//     .get(likeKeywords, {
//       headers: {
//         "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
//       }
//     })
//     .then((response) => {
//       //console.log(response.data.fetchClinic);
//       dispatch({
//         type: LIKE_KEYWORDS,
//         payload: response.data.data,
//       });
//     })
//     .catch((error) => console.log(error));
// };

export const editKeywordAction = (formData, KeywordId) => (dispatch) => {
  axiosInstance
  .put(editKeyword+"/"+KeywordId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_KEYWORD,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveKeywordAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addKeyword, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_KEYWORD,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteKeywordAction = (KeywordId) => (dispatch) => {
  axiosInstance
  .delete(deleteKeyword+"/"+KeywordId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_KEYWORD,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

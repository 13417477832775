import { GET_ALL_TREATMENTS, GET_ALL_TREATMENT_BODYPARTS, GET_SINGLE_TREATMENT, EDIT_TREATMENT, SAVE_TREATMENT, DELETE_TREATMENT } from "../actionType";

const initialState = {
  treatments: [],
  treatmentBodyparts: [],
  treatment: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TREATMENTS:
      return {
        ...state,
        treatments: action.payload,
      };

    case GET_ALL_TREATMENT_BODYPARTS:
      return {
        ...state,
        treatmentBodyparts: action.payload,
      };

    case GET_SINGLE_TREATMENT:
      return {
        ...state,
        treatment: action.payload,
      };

    case EDIT_TREATMENT:
      return {
        ...state,
      };

    case SAVE_TREATMENT:
      return {
        ...state,
      };

    case DELETE_TREATMENT:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

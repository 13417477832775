import { GET_ALL_THERAPY_BENEFIT, EDIT_THERAPY_BENEFIT, SAVE_THERAPY_BENEFIT, DELETE_THERAPY_BENEFIT } from "../actionType";
import { axiosInstance, getAllTherapyBenefit, addTherapyBenefit, editTherapyBenefit, deleteTherapyBenefit } from "../../services/Apis";

export const getAllIvTherapyBenefitAction = (ivTherapyId) => (dispatch) => {
  axiosInstance
    .get(getAllTherapyBenefit+"/"+ivTherapyId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchDoctor[0]);
      dispatch({
        type: GET_ALL_THERAPY_BENEFIT,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editIvTherapyBenefitAction = (data, benefitId, ivTherapyId) => (dispatch) => {
  axiosInstance
  .put(editTherapyBenefit+"/"+benefitId, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_THERAPY_BENEFIT,
      payload: response.data,
    });
  })
  .then(dispatch(getAllIvTherapyBenefitAction(ivTherapyId)))
  .catch((error) => console.log(error));
};

export const saveIvTherapyBenefitAction = (formData, ivTherapyId) => (dispatch) => {
  axiosInstance
  .post(addTherapyBenefit, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_THERAPY_BENEFIT,
      payload: response.data,
    });
  })
  .then(dispatch(getAllIvTherapyBenefitAction(ivTherapyId)))
  .catch((error) => console.log(error));
};

export const deleteIvTherapyBenefitAction = (ivTherapyId) => (dispatch) => {
  axiosInstance
  .delete(deleteTherapyBenefit+"/"+ivTherapyId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_THERAPY_BENEFIT,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

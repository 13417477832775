/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import { s3Url } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllDiseaseTreatmentsAction, deleteDiseaseTreatmentAction } from '../../../store/DiseaseTreatmentManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.diseaseTreatment);
  const dispatch = useDispatch();

  const [diseaseTreatments, setDiseaseTreatments] = useState([]);
  const [allDiseaseTreatments, setAllDiseaseTreatments] = useState([]);

  useEffect(() => {
    dispatch(getAllDiseaseTreatmentsAction(props.diseaseId));       
  }, []);

  useEffect(() => {
    //Lists
    if (state.diseaseTreatments !== undefined) {
      setDiseaseTreatments(state.diseaseTreatments);
    }
  }, [state]);  

  const handleDeleteTreatment = (index) => {
    dispatch(deleteDiseaseTreatmentAction(diseaseTreatments[index].id));   
    dispatch(getAllTreatmentsAction());
  }

  const handleEditTreatment = (treatment) => {
    //console.log(technique);
    props.setTreatmentId(treatment.treatment_id);
    props.setDescription(treatment.description);
    props.setDescription1(treatment.description);
    props.setDiseaseTreatmentId(treatment.id);
    props.setTreatment(treatment.treatment);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    const diseaseTreatment1 = diseaseTreatments.map((diseaseTreatment, index) => (
      {
        treatment: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {diseaseTreatment.treatment.title}
        </MDTypography>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditTreatment(diseaseTreatment)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteTreatment(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllDiseaseTreatments(diseaseTreatment1);
  }, [diseaseTreatments]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Treatment", accessor: "treatment", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allDiseaseTreatments
  };
}

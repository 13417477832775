/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

import { Link } from "react-router-dom";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllBodyPartSymptomsAction, deleteBodyPartSymptomAction } from '../../../store/BodyPartSymptomManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

let gender = [{ 1: "Male" }, { 2: "Female" }]

export default function data(props) {
  const state = useSelector(state=> state.bodyPartSymptom);
  const dispatch = useDispatch();

  const [symptoms, setSymptoms] = useState([]);
  const [allSymptoms, setAllSymptoms] = useState([]);


  useEffect(() => {
    dispatch(getAllBodyPartSymptomsAction(props.bodyPartId, props.symptomId));       
  }, []);

  useEffect(() => {
    //Lists
    if (state.symptoms !== undefined) {
      setSymptoms(state.symptoms);
    }
  }, [state]);

  const handleDeleteSymptom = (index) => {
    dispatch(deleteBodyPartSymptomAction(symptoms[index].id));   
    dispatch(getAllBodyPartSymptomsAction(props.bodyPartId));
  }

  const handleEditSymptom = (symptom) => {
    props.setGender(symptom.gender);
    props.setSymptom(symptom.symptom);
    props.setSymptomId(symptom.symptom_id);
    props.setSymptomDiseases(symptom.disease);
    props.setBodyPartSymptomId(symptom.id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    
    const allSymptoms1 = symptoms.map((symptom, index) => (
      {
        symptom: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {symptom.symptom.name}
        </MDTypography>,
        gender: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {symptom.gender}
        </MDTypography>,
        disease: <a onClick={props.handleOpenDisease}><MDButton style={buttonStyle} color="success" variant="gradient" size="sm">Disease</MDButton></a>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={()=>handleEditSymptom(symptom)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteSymptom(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllSymptoms(allSymptoms1);
  }, [symptoms]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Symptom", accessor: "symptom", width: "30%", align: "left" },
      { Header: "Gender", accessor: "gender", width: "30%", align: "left" },
      { Header: "Disease", accessor: "disease", width: "30%", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: allSymptoms
  };
}

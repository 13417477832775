import { GET_ALL_PAGES, EDIT_PAGES, SAVE_PAGES, DELETE_PAGES } from "../actionType";
import { axiosInstance, getAllPages, addPage, editPage, deletePage } from "../../services/Apis";

export const getAllPagesAction = () => (dispatch) => {
  axiosInstance
    .get(getAllPages, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.fetchBodyPart);
      dispatch({
        type: GET_ALL_PAGES,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editPageAction = (data, pageId) => (dispatch) => {
  axiosInstance
  .put(editPage+"/"+pageId, data, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_PAGES,
      payload: response.data,
    });
  })
  .then(dispatch(getAllPagesAction()))
  .catch((error) => console.log(error));
};

export const savePageAction = (formData, cityId) => (dispatch) => {
  axiosInstance
  .post(addPage, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_PAGES,
      payload: response.data,
    });
  })
  .then(dispatch(getAllPagesAction()))
  .catch((error) => console.log(error));
};

export const deletePageAction = (id, cityId) => (dispatch) => {
  axiosInstance
  .delete(deletePage+"/"+id, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_PAGES,
      payload: response.data,
    });
  })
  .then(dispatch(getAllPagesAction()))
  .catch((error) => console.log(error));
};

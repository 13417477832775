import { GET_ALL_IV_THERAPY_TESTIMONIALS, EDIT_IV_THERAPY_TESTIMONIAL, SAVE_IV_THERAPY_TESTIMONIAL, DELETE_IV_THERAPY_TESTIMONIAL } from "../actionType";
import { axiosInstance, getAllIvTherapyTestimonials, addIvTherapyTestimonial, editIvTherapyTestimonial, deleteIvTherapyTestimonial } from "../../services/Apis";

export const getAllTestimonialsAction = (treatmentId) => (dispatch) => {
  axiosInstance
    .get(getAllIvTherapyTestimonials+"/"+treatmentId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      console.log("asdsad",response.data.data);
      dispatch({
        type: GET_ALL_IV_THERAPY_TESTIMONIALS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editTestimonialAction = (formData, testimonialId, treatmentId) => (dispatch) => {
  axiosInstance
  .put(editIvTherapyTestimonial+"/"+testimonialId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_IV_THERAPY_TESTIMONIAL,
      payload: response.data,
    });
  }).then(dispatch(getAllTestimonialsAction(treatmentId)))
  .catch((error) => console.log(error));
};

export const saveTestimonialAction = (formData, treatmentId) => (dispatch) => {
  axiosInstance
  .post(addIvTherapyTestimonial, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_IV_THERAPY_TESTIMONIAL,
      payload: response.data,
    });
  }).then(dispatch(getAllTestimonialsAction(treatmentId)))
  .catch((error) => console.log(error));
};

export const deleteTestimonialAction = (testimonialId) => (dispatch) => {
  axiosInstance
  .delete(deleteIvTherapyTestimonial+"/"+testimonialId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_IV_THERAPY_TESTIMONIAL,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

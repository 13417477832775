import React, { useState, useRef } from 'react';

import axios from "axios";

import S3 from "react-aws-s3";

import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../config';

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import majorCategoryTableData from "layouts/majorCategory/data/majorCategoryTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getAllMajorCategorysAction, editMajorCategoryAction, saveMajorCategoryAction } from '../../store/MajorCategoryManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let iconImageName1 = "";
let mainAdImageName1 = "";

function Banners() {
  const dispatch = useDispatch();

  const [icon, setIcon] = React.useState([]);
  const [iconName, setIconName] = React.useState("");
  const [iconImageName, setIconImageName] = React.useState("");

  const [adImage, setAdImage] = React.useState([]);
  const [adImageName, setAdImageName] = React.useState("");
  const [mainAdImageName, setMainAdImageName] = React.useState("");

  const [showIconName, setShowIconName] = React.useState("");
  const [showAdImageName, setShowAdImageName] = React.useState("");

  const [s3fileUrl, setS3FileUrl] = React.useState("");

  //Form Data
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [majorCategoryId, setMajorCategoryId] = useState("");
  const [showActive, setShowActive] = React.useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  //const handleClose = () => setOpen(false);

  const handleClose = () => {
    setName("");
    setDescription("");
    setShowIconName("");
    setShowAdImageName("");
    setMajorCategoryId("");
    setShowActive(false);
    setOpen(false);
  };

  const { columns, rows } = majorCategoryTableData({handleOpen, setName, setDescription, setShowIconName, setShowAdImageName, setMajorCategoryId, setShowActive });

  const fileInput = useRef();

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g,"");  
    var random = ("" + Math.random()).substring(2, 8); 
    var random_number = timestamp+random;  
    return random_number;
  }

  const handleIconnput = (e) => {
    // handle validations
    //alert(1);
    setIcon(e.target.files[0]);
    setIconName(getRandomFileName()+e.target.files[0].name);
  }

  const handleAdImageInput = (e) => {
    // handle validations
    //alert(1);
    setAdImageName(e.target.files[0]);
    setAdImageName(getRandomFileName()+e.target.files[0].name);
  }

  
  const uploadMajorCategory = (event) => {
    event.preventDefault();
    const config = {
      bucketName : bucketName,
      dirName : "majorCategory",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (majorCategoryId)
    {
      if(iconName != "")
      {
        if(showIconName == ""){
          ReactS3Client.uploadFile(icon, iconName)
          .then(data1 => {
            iconImageName1 = iconName;
            //setIconImageName(imageName);
          })
          iconImageName1 = iconName;
        }else{
          ReactS3Client
          .deleteFile(showIconName)
          .then((response) => {
            ReactS3Client.uploadFile(icon, iconName)
            .then(data1 => {              
              //setIconImageName(imageName);
            })
          })
          .catch(err => console.error(err));
          iconImageName1 = iconName;
        }        
      }else{
        console.log(showIconName);
        iconImageName1 = showIconName;
        //setIconImageName(showIconName);
      }

      if(adImageName != "")
      {
        if(showAdImageName == "")
        {
          ReactS3Client.uploadFile(adImage, adImageName)
          .then(data1 => {
            //mainAdImageName1 = adImageName;
            //setMainAdImageName(imageName);
          })
          mainAdImageName1 = adImageName;
        }else{
          ReactS3Client
          .deleteFile(showAdImageName)
          .then((response) => {
            ReactS3Client.uploadFile(adImage, adImageName)
            .then(data1 => {
              
              //setMainAdImageName(imageName);
            })
          })
          .catch(err => console.error(err));
          mainAdImageName1 = adImageName;
        }
      }else{
        mainAdImageName1 = showAdImageName;
        //setMainAdImageName(showAdImageName);
      }

      const formData = {
        "name" : name,
        "description" : description,
        "icon" : iconImageName1,
        "ad_image" : mainAdImageName1
      };
      console.log(formData);
      dispatch(editMajorCategoryAction(formData, majorCategoryId));
      setMajorCategoryId("");
      dispatch(getAllMajorCategorysAction()); 
      handleClose();
    }else{
      const ReactS3Client = new S3(config);
      if(iconName != "")
      {
        ReactS3Client.uploadFile(icon, iconName)
        .then(data1 => {
        });
      }

      if(adImageName != "")
      {
        ReactS3Client.uploadFile(adImage, adImageName)
        .then(data1 => {
        });
      }

      const formData = {
        "name" : name,
        "description" : description,
        "icon" : iconName,
        "ad_image" : adImageName
      }
      dispatch(saveMajorCategoryAction(formData));
      dispatch(getAllMajorCategorysAction()); 
      handleClose();
    }
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Major Category
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadMajorCategory}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Name</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Description</InputLabel>
                      <Input id="description" aria-describedby="my-helper-text" value={description} onChange={(e) => setDescription(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Icon</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleIconnput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {(showActive && showIconName != "")? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Icon</InputLabel>
                      <Box component="img" src={s3Url+"/majorCategory/"+showIconName}></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Ad Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleAdImageInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {(showActive && showAdImageName != "")? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Ad Image</InputLabel>
                      <Box component="img" src={s3Url+"/majorCategory/"+showAdImageName}></Box>
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Major Categorys
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Banners;

import { GET_ALL_TEMPLATE, EDIT_TEMPLATE, SAVE_TEMPLATE, DELETE_TEMPLATE } from "../actionType";
import { axiosInstance, getAllTemplate, addTemplate, editTemplate, deleteTemplate } from "../../services/Apis";

export const getAllTemplatesAction = () => (dispatch) => {
  axiosInstance
    .get(getAllTemplate, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      //console.log(response.data.data);
      dispatch({
        type: GET_ALL_TEMPLATE,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editTemplateAction = (formData, templateId) => (dispatch) => {
  axiosInstance
  .put(editTemplate+"/"+templateId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_TEMPLATE,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveTemplateAction = (formData) => (dispatch) => {
  axiosInstance
  .post(addTemplate, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_TEMPLATE,
      payload: response.data,
    });
  })
  .then(dispatch(getAllTemplatesAction()))
  .catch((error) => console.log(error));
};

export const deleteTemplateAction = (templateId) => (dispatch) => {
  axiosInstance
  .delete(deleteTemplate+"/"+templateId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_TEMPLATE,
      payload: response.data,
    });
  })
  .then(dispatch(getAllTemplatesAction()))
  .catch((error) => console.log(error));
};

import { GET_ALL_URLS, EDIT_URL, SAVE_URL, DELETE_URL } from "../actionType";
import { axiosInstance, getAllTreatmentUrl, addTreatmentUrl, editTreatmentUrl, deleteTreatmentUrl } from "../../services/Apis";

export const getAllUrlsAction = (treatmentId) => (dispatch) => {
  console.log('asdasd', treatmentId)
  axiosInstance
    .get(getAllTreatmentUrl+"/"+treatmentId, {
      headers: {
        "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
      }
    })
    .then((response) => {
      console.log('data',response.data);
      dispatch({
        type: GET_ALL_URLS,
        payload: response.data.data,
      });
    })
    .catch((error) => console.log(error));
};

export const editUrlAction = (formData, faqId) => (dispatch) => {
  axiosInstance
  .put(editTreatmentUrl+"/"+faqId, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: EDIT_URL,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const saveUrlAction = (formData) => (dispatch) => {
  console.log('Ad', formData);
  axiosInstance
  .post(addTreatmentUrl, formData, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: SAVE_URL,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

export const deleteUrlAction = (faqId) => (dispatch) => {
  axiosInstance
  .delete(deleteTreatmentUrl+"/"+faqId, {
    headers: {
      'content-type': 'application/json',
      "Authorization": "Bearer "+sessionStorage.getItem('userToken') //the token is a variable which holds the token
    }
  })
  .then((response) => {
    dispatch({
      type: DELETE_URL,
      payload: response.data,
    });
  })
  .catch((error) => console.log(error));
};

import React, { useState, useEffect, useRef } from 'react';

import S3 from "react-aws-s3";
import { Editor } from '@tinymce/tinymce-react';

import { accessKeyId, secretAccessKey, region, s3Url, bucketName, editorKey } from '../../config';
// @mui material components
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup, Select, MenuItem } from '@mui/material';

import { useParams } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import cityCenterTableData from "layouts/CityCenter/data/cityCenterTableData";

import { useDispatch, useSelector } from 'react-redux';
import { saveCityCenterAction, editCityCenterAction, getAllCityCentersAction } from '../../store/CityCenterManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function IvTherapyBenefit() {
  const dispatch = useDispatch();
  let { cityId } = useParams();


  const [showActive, setShowActive] = React.useState(false);
  const [file, setFile] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [showName, setShowName] = React.useState("");
  const [s3fileUrl, setS3FileUrl] = React.useState("");

  //Form Data
  const [name, setName] = useState("");
  const [location, setLocation] = useState('');
  const [link, setLink] = useState('');
  const [alt, setAlt] = useState('');

  const [centreId, setCentreId] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    setLocation("");
    setLink("");
    setShowName("");
    setShowActive(false);
    setAlt("");
    setCentreId("");
    setOpen(false);
  };

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    var random = ("" + Math.random()).substring(2, 8);
    var random_number = timestamp + random;
    return random_number;
  }

  const fileInput = useRef();

  const { columns, rows } = cityCenterTableData({ handleOpen, setName, setShowName, setShowActive, setAlt, setLocation, setLink, setCentreId });

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    setFileName(getRandomFileName() + e.target.files[0].name);
  }

  const uploadDoctor = (event) => {
    event.preventDefault();
    const config = {
      bucketName: bucketName,
      dirName: "cityCentre",
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (centreId) {
      if (fileName != "") {
        console.log("filename", fileName);
        ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            setS3FileUrl(data.location);
            const formData = {
              "centre_name": name,
              "centre_image": fileName,
              "alt_text": alt,
              "centre_location": location,
              "centre_link": link,
              "city_id": cityId
            }
            setFileName("");
            dispatch(editCityCenterAction(formData, cityId, centreId));
            dispatch(getAllCityCentersAction(cityId));
            handleClose();
          })
          .catch(err => console.error(err))
      } else {
        const formData = {
          "centre_name": name,
          "centre_image": showName,
          "alt_text": alt,
          "city_id": cityId,
          "centre_location": location,
          "centre_link": link,
        }
        setFileName("");
        dispatch(editCityCenterAction(formData, cityId, centreId));
        dispatch(getAllCityCentersAction(cityId));
        handleClose();
      }
    } else {
      ReactS3Client.uploadFile(file, fileName)
        .then(data => {
          //console.log(data.location);
          setS3FileUrl(data.location);
          const formData = {
            "centre_name": name,
            "centre_image": fileName,
            "alt_text": alt,
            "centre_location": location,
            "centre_link": link,
            "city_id": cityId
          }
          console.log(formData);
          setFileName("");
          dispatch(saveCityCenterAction(formData, cityId));
          dispatch(getAllCityCentersAction(cityId));
          handleClose();
        })
    }
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Centre
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
            disableAutoFocus={true} //for disable modal
            disableEnforceFocus={true} //for disable modal
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadDoctor}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Name</InputLabel>
                      <Input id="name" value={name} aria-describedby="my-helper-text" onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Location</InputLabel>
                      <Input id="location" aria-describedby="my-helper-text" value={location} onChange={(e) => setLocation(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Link</InputLabel>
                      <Input id="link" aria-describedby="my-helper-text" value={link} onChange={(e) => setLink(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Alt Text</InputLabel>
                      <Input id="alt tag" aria-describedby="my-helper-text" value={alt} onChange={(e) => setAlt(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={showActive ? "show" : "hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Image</InputLabel>
                      <Box component="img" src={s3Url + "/cityCentre/" + showName} style={{ "width": 100 }}></Box>
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit" disabled={!name ? true : false}>
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  City Center
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default IvTherapyBenefit;

import { GET_ALL_LOCATIONS, GET_SINGLE_LOCATION, GET_ALL_ACTIVE_LOCATIONS, EDIT_LOCATION, SAVE_LOCATION, DELETE_LOCATION } from "../actionType";

const initialState = {
  locations: [],
  location: [],
  activeLocations: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };

    case GET_SINGLE_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    case GET_ALL_ACTIVE_LOCATIONS:
      return {
        ...state,
        activeLocations: action.payload,
      };

    case EDIT_LOCATION:
      return {
        ...state,
      };

    case SAVE_LOCATION:
      return {
        ...state,
      };

    case DELETE_LOCATION:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

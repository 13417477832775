import { GET_ALL_NEWSLETTER, EDIT_NEWSLETTER, SAVE_NEWSLETTER, DELETE_NEWSLETTER } from "../actionType";

const initialState = {
  newsletters: [],
  //likes: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NEWSLETTER:
      return {
        ...state,
        newsletters: action.payload,
      };

    case EDIT_NEWSLETTER:
      return {
        ...state,
      };

    case SAVE_NEWSLETTER:
      return {
        ...state,
      };

    case DELETE_NEWSLETTER:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
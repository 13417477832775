/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllSpecializationsAction, deleteSpecializationAction } from '../../../store/SpecializationManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.specialization);
  const dispatch = useDispatch();

  const [specializations, setSpecializations] = useState([]);
  const [allSpecializations, setAllSpecializations] = useState([]);

  useEffect(() => {
    dispatch(getAllSpecializationsAction());       
  }, []);

  useEffect(() => {
    //Lists
    
    if (state.specializations !== undefined) {
      //console.log(state.specializations);
      setSpecializations(state.specializations);
    }
  }, [state]);

  const handleDeleteSpecialization = (index) => {
    dispatch(deleteSpecializationAction(specializations[index].specialization_id));   
    dispatch(getAllSpecializationsAction());        
  }

  const handleEditSpecialization = (specialization) => {
    //console.log(condition);
    props.setName(specialization.name);
    props.setSpecializationId(specialization.specialization_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    //console.log(specializations);
    const allSpecializations1 = specializations.map((specialization, index) => (
      {
        specializationName: specialization.name,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditSpecialization(specialization)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteSpecialization(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllSpecializations(allSpecializations1);
  }, [specializations]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Name", accessor: "specializationName", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allSpecializations
  };
}

/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';
import S3 from "react-aws-s3";
import { Link } from "react-router-dom";
import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllSymptomAction, deleteSymptomAction } from '../../../store/SymptomManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.symptom);
  const dispatch = useDispatch();

  const [symptoms, setsymptoms] = useState([]);
  const [allsymptoms, setAllsymptoms] = useState([]);

  useEffect(() => {
    dispatch(getAllSymptomAction(props.bodyPartId));       
  }, []);

  useEffect(() => {
    //Lists
    if (state.symptoms !== undefined) {
      setsymptoms(state.symptoms);
    }
  }, [state]);

  const handleDeleteSymptom = (index) => {
    console.log(symptoms[index].symptom_id);
    dispatch(deleteSymptomAction(symptoms[index].symptom_id)); 
    const config = {
      bucketName : bucketName,
      dirName : "symptom",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    ReactS3Client
    .deleteFile(s3Url+"/symptom/"+symptoms[index].upload_image)
    .then((response) => {        
      dispatch(getAllSymptomAction(props.bodyPartId));
    })
    .catch(err => console.error(err))
  }

  const handleEditSymptom = (symptom) => {
    //console.log(cause);
    props.setName(symptom.name);
    props.setShowName(symptom.upload_image);
    props.setSymptomId(symptom.symptom_id);
    props.setAltText(symptom.symptom_alt_text);
    props.setShowActive(true);
    props.handleOpen();

  };

  useEffect(() => {
    
    const allsymptoms1 = symptoms.map((symptom, index) => (
      {
        symptomImage: <Author image={s3Url+"/symptom/"+symptom.upload_image} name={symptom.name} />,
        symptomTitle: symptom.name,
        symptomDescription: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {symptom.description}
        </MDTypography>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditSymptom(symptom)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteSymptom(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllsymptoms(allsymptoms1);
  }, [symptoms]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Image", accessor: "symptomImage", width: "30%", align: "left" },
      { Header: "Title", accessor: "symptomTitle", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allsymptoms
  };
}

import { GET_ALL_URLS, EDIT_URL, SAVE_URL, DELETE_URL } from "../actionType";

const initialState = {
  urls: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_URLS:
      return {
        ...state,
        urls: action.payload,
      };

    case EDIT_URL:
      return {
        ...state,
      };

    case SAVE_URL:
      return {
        ...state,
      };

    case DELETE_URL:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

import React, { useState, useRef } from 'react';

import S3 from "react-aws-s3";

import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../config';

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import bannerTableData from "layouts/banner/data/bannerTableData";

import { useDispatch, useSelector } from 'react-redux';
import { saveBannerAction, editBannerAction, getAllBannersAction } from '../../store/BannerManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Banners() {
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  const [file, setFile] = React.useState([]);  
  const [fileName, setFileName] = React.useState("");  
  const [showName, setShowName] = React.useState(""); 
  const [file2, setFile2] = React.useState([]);
  const [fileName2, setFileName2] = React.useState("");
  const [showName2, setShowName2] = React.useState("");
  const [s3fileUrl, setS3FileUrl] = React.useState("");

  //Form Data
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [bannerId, setBannerId] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    setDescription("");
    setBannerId("");
    setShowName("");
    setShowName2("");
    setShowActive(false);
    setOpen(false);
  };

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g,"");  
    var random = ("" + Math.random()).substring(2, 8); 
    var random_number = timestamp+random;  
    return random_number;
  }

  const fileInput = useRef();
  const fileInput2 = useRef();

  const { columns, rows } = bannerTableData({handleOpen, setName, setDescription, setShowName, setShowName2, setShowActive, setBannerId});
  const handleFileInput = (e) => {
    // handle validations
    //alert(1);
    setFile(e.target.files[0]);
    setFileName(getRandomFileName()+e.target.files[0].name);
  }

  const handleFileInput2 = (e) => {
    // handle validations
    setFile2(e.target.files[0]);
    setFileName2(getRandomFileName()+e.target.files[0].name);
  }

  const uploadBanner = (event) => {
    event.preventDefault();
    const config = {
      bucketName : bucketName,
      dirName : "banner",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);

    let mobileImageName = "";

    if(fileName2 != "")
    {
      if(showName2 == "" || showName2 == null){
        ReactS3Client.uploadFile(file2, fileName2)
        .then(data1 => {
          mobileImageName = fileName2;
          //setMainImageName(imageName);
        })
        mobileImageName = fileName2;
      }else{
        ReactS3Client
        .deleteFile(showName2)
        .then((response) => {
          ReactS3Client.uploadFile(file2, fileName2)
          .then(data1 => {              
            //setMainImageName(imageName);
          })
        })
        .catch(err => console.error(err));
        mobileImageName = fileName2;
      }        
    }else{
      mobileImageName = showName2;
    }

    if (bannerId)
    {
      if(fileName != "")
      {
        ReactS3Client
        .deleteFile(s3Url+"/banner/"+showName)
        .then((response) => {
          ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            console.log(data.location);
            setS3FileUrl(data.location);
            const formData = {
              "title" : name,
              "description" : description,
              "image" : fileName,
              "mobile_image": mobileImageName
            }
            dispatch(editBannerAction(bannerId, formData));
            setBannerId("");
            //dispatch(getAllBannersAction()); 
            handleClose();
          })
        })
        .catch(err => console.error(err))
      }else{
        const formData = {
          "title" : name,
          "description" : description
        }
        dispatch(editBannerAction(bannerId, formData));
        setBannerId("");
        //dispatch(getAllBannersAction()); 
        handleClose();
      }

    }else{
      ReactS3Client.uploadFile(file, fileName)
      .then(data => {
        //console.log(data.location);
        setS3FileUrl(data.location);
        const formData = {
          "title" : name,
          "description" : description,
          "image" : fileName,
          "mobile_image" : mobileImageName
        }
        dispatch(saveBannerAction(formData));
        //dispatch(getAllBannersAction()); 
        handleClose();
      })
    }
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Banner
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadBanner}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Title</InputLabel>
                      <Input id="title" value={name} aria-describedby="my-helper-text" onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Description</InputLabel>
                      <Input id="description" value={description} aria-describedby="my-helper-text" onChange={(e) => setDescription(e.target.value)}  />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Mobile Image</InputLabel>
                      <Input type="file" ref={fileInput2} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput2} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Mobile Image</InputLabel>
                      <Box component="img" src={s3Url+"/banner/"+showName2}></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className = {showActive? "show":"hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Image</InputLabel>
                      <Box component="img" src={s3Url+"/banner/"+showName}></Box>
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Banners
                </MDTypography>                
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>          
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Banners;

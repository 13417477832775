import { GET_ALL_IV_THERAPY_FAQS, EDIT_IV_THERAPY_FAQ, SAVE_IV_THERAPY_FAQ, DELETE_IV_THERAPY_FAQ } from "../actionType";

const initialState = {
  faqs: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_IV_THERAPY_FAQS:
      return {
        ...state,
        faqs: action.payload,
      };

    case EDIT_IV_THERAPY_FAQ:
      return {
        ...state,
      };

    case SAVE_IV_THERAPY_FAQ:
      return {
        ...state,
      };

    case DELETE_IV_THERAPY_FAQ:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;

/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';

import S3 from "react-aws-s3";

import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../../config';

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

import {useDispatch, useSelector} from 'react-redux';
import { getAllBannersAction, deleteBannerAction } from '../../../store/BannerManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.bannerManagement);
  const dispatch = useDispatch();

  const [banners, setBanners] = useState([]);
  const [allBanners, setAllBanners] = useState([]);


  useEffect(() => {
    dispatch(getAllBannersAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (state.banners !== undefined) {
      setBanners(state.banners);
    }
    // axios.get("http://api.tamiraplatform.metricserp.net/api/v1/banner/fetch-all-banner",{
    //   headers: {
    //     "x-auth-token": sessionStorage.getItem('userToken') //the token is a variable which holds the token
    //   }
    // })
    // .then(result => {
    //   console.log(result.data.fetchBanner);
    //   setBanners(result.data.fetchBanner);
    // })
  }, [state]);

  const handleDeleteBanner = (index) => {
    const config = {
      bucketName : bucketName,
      dirName : "banner",
      region : region,
      accessKeyId : accessKeyId,
      secretAccessKey : secretAccessKey
    };
    const ReactS3Client = new S3(config);
    ReactS3Client
    .deleteFile(s3Url+"/banner/"+banners[index].image)
    .then((response) => {
      dispatch(deleteBannerAction(banners[index].banner_id));   
      //dispatch(getAllBannersAction());
    })
    .catch(err => console.error(err))
  }

  const handleEditBanner = (banner) => {
    props.setName(banner.title);
    props.setDescription(banner.description);
    props.setShowName(banner.image);
    props.setShowName2(banner.mobile_image);
    props.setBannerId(banner.banner_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    
    const allBanners1 = banners.map((banner, index) => (
      {
        bannerImage: <Author image={s3Url+"/banner/"+banner.image} name={s3Url+"/banner/"+banner.image} />,
        bannerTitle: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {banner.title}
        </MDTypography>,
        bannerDesription: <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {banner.description}
        </MDTypography>,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={()=>handleEditBanner(banner)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteBanner(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    setAllBanners(allBanners1);
  }, [banners]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Banner Image", accessor: "bannerImage", width: "30%", align: "left" },
      { Header: "Banner Title", accessor: "bannerTitle", width: "30%", align: "left" },
      { Header: "Banner Description", accessor: "bannerDesription", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allBanners
  };
}

/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";

import { accessKeyId, secretAccessKey, region, s3Url, bucketName } from '../../../config';
import S3 from "react-aws-s3";

// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDBadge";

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, FormGroup } from '@mui/material';

import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import {useDispatch, useSelector} from 'react-redux';
import { getAllMajorCategorysAction, deleteMajorCategoryAction } from '../../../store/MajorCategoryManagement/action';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  cursor: "pointer",
};

export default function data(props) {
  const state = useSelector(state=> state.majorCategory);
  const dispatch = useDispatch();

  const [majorCategorys, setMajorCategorys] = useState([]);
  const [allMajorCategorys, setAllMajorCategorys] = useState([]);

  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const config = {
    bucketName : bucketName,
    dirName : "majorCategory",
    region : region,
    accessKeyId : accessKeyId,
    secretAccessKey : secretAccessKey
  };

  useEffect(() => {
    dispatch(getAllMajorCategorysAction());       
  }, []);

  useEffect(() => {
    //Lists
    if (state.categories !== undefined) {
      setMajorCategorys(state.categories);
    }
  }, [state]);

  const handleDeleteMajorCategory = (index) => {
    const icon = majorCategorys[index].icon;
    const ad_image = majorCategorys[index].ad_image;
    const ReactS3Client = new S3(config);

    ReactS3Client
    .deleteFile(icon)
    .then((response) => {
      ReactS3Client
      .deleteFile(ad_image)
      .then((response) => {
        dispatch(deleteMajorCategoryAction(majorCategorys[index].major_category_id));   
        dispatch(getAllMajorCategorysAction());
      })
    })
    .catch(err => console.error(err))
        
  }

  const fileInput = useRef();

  const handleEditMajorCategory = (majorCategory) => {
    //console.log(cause);
    props.setName(majorCategory.name);
    props.setDescription(majorCategory.description);
    props.setShowIconName(majorCategory.icon);
    props.setShowAdImageName(majorCategory.ad_image);
    props.setMajorCategoryId(majorCategory.major_category_id);
    props.setShowActive(true);
    props.handleOpen();
  };

  useEffect(() => {
    
    const allMajorCategorys1 = majorCategorys.map((majorCategory, index) => (
      {
        majorCategoryName: majorCategory.name,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Status" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <>
            <MDBox ml={-1}>
              <MDButton color="success" style={buttonStyle} variant="gradient" size="sm" onClick={() => handleEditMajorCategory(majorCategory)}>
                Edit
              </MDButton>
            </MDBox>
            <MDBox ml={-1}>
              <MDButton style={buttonStyle} onClick={() => handleDeleteMajorCategory(index)}>
                Delete
              </MDButton>
            </MDBox>
          </>
        ),
      }
    ));
    console.log(allMajorCategorys1);
    setAllMajorCategorys(allMajorCategorys1);
  }, [majorCategorys]);


  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  return {
    columns: [
      { Header: "Major Category Name", accessor: "majorCategoryName", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: allMajorCategorys
  };
}
